import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import {
  Box,
  Rule,
  Mobile,
  Tabs,
  Input,
  Button,
  Container,
  Icon,
  Checkbox,
} from "../Bitter";
import MenuContainer from "./MenuContainer";

import Toast from "react-hot-toast";
import { Colors, Functions, Cities } from "../Helpers";
import { Col } from "react-grid-system";
import { API } from "../Modules";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "+90 ",
      message: "",
      date: "",
      city: "İSTANBUL",
      checked: false,
    };
  }

  send = async () => {
    const response = await API.request("/website/collaborations", {
      parameters: {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message + ". Şehir: " + this.state.city,
        date: this.state.date,
      },
    });

    if (response.success) {
      Toast.success(
        "Mesajınız tarafımıza iletilmiştir. En kısa sürede sizinle iletişime geçilecektir.",
        { duration: 5000 }
      );
    }

    this.setState({
      name: "",
      email: "",
      phone: "",
      message: "",
      date: "",
    });
  };

  render() {
    const { name, email, phone, message, city, checked } = this.state;

    return (
      <Box>
        <Mobile
          render={(mobile) => (
            <img
              style={{ display: "block", width: "100%", height: "auto" }}
              src={
                mobile
                  ? "/assets/i/element-2-mobile.png"
                  : "/assets/i/element-2.png"
              }
            />
          )}
        />

        <Mobile
          render={(mobile, minimum, gutter, spacing, screen) => (
            <>
              <Box
                mode="padding"
                all={mobile ? 15 : 64}
                style={{ marginLeft: "auto", marginRight: "auto" }}
                width={
                  ["xs", "sm", "md", "lg"].includes(screen) ? "100%" : "75%"
                }
                display="flex"
                direction="column"
              >
                <Box display="flex" direction="column">
                  <Rule rule="Body" mode="padding" bottom={16} size={32}>
                    <span>
                      <Rule
                        size={32}
                        color={Colors.primary}
                        rule="Body"
                        weight={700}
                      >
                        backup
                      </Rule>
                    </span>{" "}
                    İş Modeli
                  </Rule>
                  <Rule rule="Body" mode="padding" bottom={16}>
                    Mutlak başarı ve %100 memnuniyet odaklı bütüncül yaklaşım.
                  </Rule>
                  <Rule rule="Body" mode="padding" bottom={16}>
                    <Rule color={Colors.primary} rule="Body" weight={700}>
                      backup
                    </Rule>{" "}
                    sistemi, işletme sahiplerine çok çeşitlli avantajlar sunar:
                  </Rule>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">
                      Kolay kurulum ve eğitim ile hızlıca kullanılabilir
                    </Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">
                      Ölçümlenebilir hasta-danışan memnuniyeti sağlar
                    </Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">
                      Yapay zeka ve Türkçe yazılım ile kolay kullanım
                    </Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Ekstra alan ve işgücü gerektirmez</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">
                      Yüksek Alman kalitesi ve düşük yatırım
                    </Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">
                      Hizmet portföyünüzün genişletilmesi ve teknoloji
                      entegrasyonu
                    </Rule>
                  </Box>{" "}
                  <Box display="flex" bottom={40}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">
                      Ek olarak, düzenli yazılım güncellemeleri aracılığıyla tüm
                      program ve parametrelerin sürekli optimizasyonundan
                      yararlanabilirsiniz.
                    </Rule>
                  </Box>
                  <Rule rule="Title" size={32}>
                    İş planı: bireysel ihtiyaçlara dayalı hesaplama
                  </Rule>
                  <Rule rule="Body" top={16}>
                    <span>
                      <Rule color={Colors.primary} rule="Body" weight={700}>
                        backup
                      </Rule>
                    </span>{" "}
                    sistemine yaptığınız yatırımın karşılığını hızla alabilmeniz
                    için, bireysel iş ve hizmet modelinizi geliştirmek için
                    sizinle birlikte çalışıyoruz. İş planımızı şirketinizin özel
                    gereksinimlerine, mevcut müşteri yapısına ve kişisel yatırım
                    gereksinimlerinize göre yapıyoruz. Sonraki adımlarınız
                    konusunda size tavsiyede bulunmaktan da memnuniyet duyarız.
                    Websitemiz, sosyal medya hesaplarımız ve son kullanıcıya
                    yönelik 12 yıllık tecrübemizle tüm pazarlama
                    stratejilerinize hem danışmanlık hem de destek veriyor
                    olacağız.{" "}
                  </Rule>
                  <Rule rule="Body" top={30}>
                    <span>
                      <Rule color={Colors.primary} rule="Body" weight={700}>
                        backup
                      </Rule>
                    </span>{" "}
                    sisteminizi bugün sipariş edin ve kazançlı, denenmiş ve test
                    edilmiş yenilikçi bir konseptle hizmet portföyünüzü
                    genişletin. İş projenizin planlanması ve finansmanında size
                    destek olmaktan da ayrıca mutluluk duyarız.
                  </Rule>
                  <Rule rule="Title" top={40} size={32}>
                    Başarınız için verimli pazarlama
                  </Rule>
                  <Rule top={16}>
                    İlk{" "}
                    <span>
                      <Rule color={Colors.primary} rule="Body" weight={700}>
                        backup
                      </Rule>
                    </span>{" "}
                    sistemi siparişinizle, hizmetinizi profesyonel ve etkili bir
                    şekilde sunabilmeniz için kapsamlı bir pazarlama paketi
                    alacaksınız.
                  </Rule>
                  <Rule top={40} size={32} rule="Title">
                    <span>
                      <Rule
                        color={Colors.primary}
                        size={32}
                        rule="Body"
                        weight={700}
                      >
                        backup
                      </Rule>
                    </span>{" "}
                    sisteminin bileşenlerine genel bir bakış
                  </Rule>
                  <Rule rule="Body" weight={600}>
                    Neler dahil?
                  </Rule>
                  <Rule rule="Body" top={16}>
                    <span>
                      <Rule color={Colors.primary} rule="Body" weight={700}>
                        backup
                      </Rule>
                    </span>{" "}
                    sisteminin ana unsurları, stimülasyon matı (yatak) ve
                    dokunmatik ekranlı kontrol ünitesidir. Teslimat kapsamına
                    dahil olan çok sayıda başka aksesuar, komple paketi
                    tamamlar.
                  </Rule>
                  <Rule rule="Body" top={16} weight={600}>
                    Eksiksiz{" "}
                    <Rule color={Colors.primary} rule="Body" weight={700}>
                      backup
                    </Rule>{" "}
                    paketi şunları içerir:
                  </Rule>
                  <Box display="flex" bottom={12} top={16}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Stimülasyon matı</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Dokunmatik ekranlı kontrol ünitesi</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Uzaktan kumanda</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Baş Yastığı</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Mat altı minderi</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Lordoz yastığı</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Yönetici kartı</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Sprey su pompası</Rule>
                  </Box>
                  <Box display="flex" bottom={12}>
                    <Icon right={12} color={Colors.primary} icon="check"></Icon>
                    <Rule rule="Body">Bağlantı kablosu</Rule>
                  </Box>
                  
                  <Box height="100%" flex={1} style={{ overflowY: "auto" }}
                  identifier="hemenbasvur"
                  >
                    <Box top={40} bottom={72} mode="padding">
                      <Input
                        value={name}
                        change={(name) => this.setState({ name })}
                        label="Ad Soyad *"
                      />
                      <Input
                        value={email}
                        change={(email) => this.setState({ email })}
                        label="E-Posta *"
                      />
                      <Input
                        value={phone}
                        change={(phone) => this.setState({ phone })}
                        label="Telefon Numarası *"
                        placeholder={"Örn: +90 555 555 55 55"}
                        mask="+99 999 999 9999"
                      />
                      <Input
                        value={city}
                        change={(city) => this.setState({ city })}
                        label="Şehir"
                        options={Cities}
                        mode="select"
                      />
                      <Input
                        value={message}
                        change={(message) => this.setState({ message })}
                        label="Mesaj"
                      />
                      <Checkbox
                        value={checked}
                        bottom={24}
                        press={(checked) => this.setState({ checked })}
                        title={
                          <>
                            <a target="_blank" href="/post/privacy-policy">
                              KVKK
                            </a>{" "}
                            ve{" "}
                            <a target="_blank" href="/post/privacy-policy">
                              Aydınlatma Metnini
                            </a>{" "}
                            okudum ve kabul ediyorum.
                          </>
                        }
                      />
                        <Link
                       
            to={checked ? `/collaboration/tesekkurler` : 1 }
            
          >
                      <Button
                        press={checked ? this.send : undefined}
                        opacity={checked ? 1 : 0.5}
                        width="100%"
                        type="primary"
                      >
                        Gönder
                      </Button>
                      </Link>
                    </Box>
                  </Box>
                 
                </Box>
              </Box>
            </>
          )}
        />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
