import React from "react";
import { connect } from "react-redux";

import {
  MenuContainer,
  HeroContainer,
  ServicesContainer,
  PostsContainer,
  FooterContainer,
} from "../Containers";
import { Box } from "../Bitter";

import Helmet from "react-helmet";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { location, history } = this.props;
    return (
      <Box
        identifier="hero"
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
        color="#EFFBFA"
      >
        <Helmet>
          <link rel="stylesheet" href="/assets/css/style-home.css" />
        </Helmet>
        <HeroContainer location={location} history={history} />
        <ServicesContainer />
        <PostsContainer />
        <FooterContainer />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
