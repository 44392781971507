import React, { useState, useEffect } from "react";

import Editor from "./Editor";
import { API } from "../../Modules";

const _ = () => {
  return {};
};

const Static = ({ render = false, slug }) => {
  const [value, set] = useState(false);

  useEffect(async () => {
    if (sessionStorage) {
      if (sessionStorage.getItem("@statics-" + slug)) {
        set(JSON.parse(sessionStorage.getItem("@statics-" + slug)));
      }
    }

    const data = await API.request("/@statics/read", {
      parameters: {
        slug,
      },
    });

    if (data.success) {
      if (data.data.length > 0) {
        set(data.data[0]);
        sessionStorage.setItem(
          "@statics-" + slug,
          JSON.stringify(data.data[0])
        );
      }
    }
  }, []);

  let image = null;

  if (value) {
    try {
      value.parameters.content.blocks.map((block) => {
        if (block.type === "image") {
          image = block.data.file.url;
        }
      });
    } catch (e) {
    } finally {
    }
  }

  if (render && image) {
    return render(image);
  }

  return null;
};

export default Static;
