import React from "react";
import { connect } from "react-redux";

import {
  MenuContainer,
  ExpertsContainer,
  FooterContainer,
} from "../Containers";
import { Box } from "../Bitter";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        <MenuContainer />
        <ExpertsContainer
          experts={[
            {
              uuid: 1,
              parameters: {
                name: "Julian",
                city: "İstanbul",
                profession: "Fizyoterapist",
                email: "Julian Gumenita",
                phone: "+905326541234",
                image:
                  "https://media-exp1.licdn.com/dms/image/C4D03AQGCa05Ezr36Pg/profile-displayphoto-shrink_800_800/0/1637687778565?e=1650499200&v=beta&t=QvEsB2UqoZy3gXzSAZd0HVIe2Mv-CsqqsMzwdndKwxA",
              },
            },
            {
              uuid: 2,
              parameters: {
                name: "Julian",
                city: "İstanbul",
                profession: "Fizyoterapist",
                email: "Julian Gumenita",
                phone: "+905326541234",
                image:
                  "https://media-exp1.licdn.com/dms/image/C4D03AQGCa05Ezr36Pg/profile-displayphoto-shrink_800_800/0/1637687778565?e=1650499200&v=beta&t=QvEsB2UqoZy3gXzSAZd0HVIe2Mv-CsqqsMzwdndKwxA",
              },
            },
            {
              uuid: 3,
              parameters: {
                name: "Julian",
                city: "İstanbul",
                profession: "Fizyoterapist",
                email: "Julian Gumenita",
                phone: "+905326541234",
                image:
                  "https://media-exp1.licdn.com/dms/image/C4D03AQGCa05Ezr36Pg/profile-displayphoto-shrink_800_800/0/1637687778565?e=1650499200&v=beta&t=QvEsB2UqoZy3gXzSAZd0HVIe2Mv-CsqqsMzwdndKwxA",
              },
            },
            {
              uuid: 4,
              parameters: {
                name: "Julian",
                city: "İstanbul",
                profession: "Fizyoterapist",
                email: "Julian Gumenita",
                phone: "+905326541234",
                image:
                  "https://media-exp1.licdn.com/dms/image/C4D03AQGCa05Ezr36Pg/profile-displayphoto-shrink_800_800/0/1637687778565?e=1650499200&v=beta&t=QvEsB2UqoZy3gXzSAZd0HVIe2Mv-CsqqsMzwdndKwxA",
              },
            },
          ]}
          cities={[
            "İstanbul",
            "Adana",
            "Adıyaman",
            "Afyon",
            "Aksaray",
            "Amasya",
            "Ankara",
          ]}
        />
        <FooterContainer />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
