import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';


import {
  Box,
  Rule,
  Mobile,
  Tabs,
  Input,
  Button,
  Container,
  Checkbox,
  ClinicSelect,
} from "../Bitter";
import MenuContainer from "./MenuContainer";

import Toast from "react-hot-toast";
import { Row, Col } from "react-grid-system";
import { API } from "../Modules";



class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      clinic: "",
      name: "",
      phone: "+90 ",
      message: "",
      date: "",
      clinics: [],
      checked: false,
    };
  }

  send = async () => {
    const response = await API.request("/website/appointments", {
      parameters: {
        clinic: this.state.clinic,
        name: this.state.name,
        phone: this.state.phone,
        message: this.state.message + " Email: " + this.state.email,
        date: this.state.date,
      },
    });

    if (response.success) {
      Toast.success(
        "Mesajınız tarafımıza iletilmiştir. İlgili merkez en kısa sürede sizinle iletişime geçecektir.",
        { duration: 7000 }
      );
    }
    this.setState({
      email: "",
      clinic: "",
      name: "",
      phone: "",
      message: "",
      date: "",
    });
  };

  async componentDidMount() {
    const clinics = await API.request("/website/clinics");
    if (clinics.success) {
      this.setState({ clinics: clinics.data });
    }

    const { location } = this.props;

    if (location.state) {
      const { name, clinic } = location.state;

      this.setState({ name, clinic });
    }
  }

  render() {
    const {
      clinic,
      name,
      phone,
      message,
      date,
      clinics,
      email,
      checked,
    } = this.state;

    const _clinics = [];
    clinics.map((c) => {
      _clinics.push({ label: c.parameters.name, value: c.uuid });
    });

    return (
      <Mobile
        render={(mobile, minimum, gutter, spacing, screen) => (
          <Box width="100%" display="flex" direction="column">
            <MenuContainer />
            <Mobile
              render={(mobile) => (
                <img
                  style={{ display: "block", width: "100%", height: "auto" }}
                  src={
                    mobile
                      ? "/assets/i/element-2-mobile.png"
                      : "/assets/i/element-2.png"
                  }
                />
              )}
            />

            <Box
              mode="padding"
              all={mobile ? 15 : 64}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              width={["xs", "sm", "md", "lg"].includes(screen) ? "100%" : "75%"}
              display="flex"
              direction="column"
            >
              <Box bottom={24}>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <img
                      width={48}
                      height={48}
                      src="/assets/i/person-icon.png"
                    />
                    <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                      <span style={{ fontWeight: 800, color: "#029ED0" }}>
                        backup
                      </span>{" "}
                      sisteminin etkileri
                    </Rule>
                    <Rule rule="Body" display="block">
                      <span style={{ fontWeight: 700, color: "#029ED0" }}>
                        backup
                      </span>{" "}
                      sistemi, sırt ve bel ağrısı, hareketsizlik, egzersiz
                      eksikliği ve kas dengesizliğinden oluşan kısır döngüyü
                      kırmak için ideal bir kombinasyon sunar: rahatlatıcı
                      sıcaklık, yumuşak bir derin masaj ve sırt kaslarının yoğun
                      şekilde güçlendirilmesi, sağlıklı bir omurganın temelini
                      oluşturur.
                    </Rule>
                  </Col>

                  <Col xs={12} sm={12} md={6}>
                    <img
                      src="/assets/i/muscle-icon.png"
                      width={36}
                      height={36}
                    />
                    <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                      Sırt ve bel kaslarını güçlendirir:
                    </Rule>
                    <Rule rule="Body" display="block">
                      Dinamik derin dalgalar, hafif elektriksel uyarılarıyla
                      sırt-bel kaslarını güçlendirirken aynı zamanda derin kas
                      katmanlarını da harekete geçirir. Bu kas egzersizi ile
                      ilgili özel olan şey, kasların aktif olarak gerilmesine
                      gerek olmamasıdır. Bu, sınırlı hareket kabiliyetine sahip
                      kişilerin bile kaslarını güçlendirebileceği, ağrıya karşı
                      koyabileceği ve semptomları önleyebileceği anlamına gelir.
                      Ayrıca sadece kaslar değil, aynı zamanda sağlıklı bir
                      omurga için çok önemli olan facia (kas zarı) da uyarılır.
                    </Rule>
                  </Col>
                </Row>
              </Box>

              <Box>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <img width={32} height={32} src="/assets/i/sun-icon.png" />
                    <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                      Sıcaklıkla rahatlama:
                    </Rule>
                    <Rule rule="Body" display="block">
                      <span style={{ fontWeight: 700, color: "#029ED0" }}>
                        backup
                      </span>{" "}
                      matı (yatağı) 40°C'ye kadar ısıtılabilir. Isı, kas
                      gerginliğini gidermeye yardımcı olur, kan dolaşımını ve
                      metabolizmayı destekler, psikolojik stresi azaltır ve daha
                      yoğun gevşemeye yol açar.
                    </Rule>
                  </Col>

                  <Col xs={12} sm={12} md={6}>
                    <img
                      src="/assets/i/potion-icon.png"
                      width={32}
                      height={32}
                    />
                    <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                      Gevşetici ve Rahatlatıcı derin doku masajı:
                    </Rule>
                    <Rule rule="Body" display="block">
                      <span style={{ fontWeight: 700, color: "#029ED0" }}>
                        backup
                      </span>{" "}
                      matı (yatağı) üzerinde, rahatlatıcı bir dokunma, yoğurma
                      veya gevşetme masajı gibi hissedilen tüm sırtta hoş, yoğun
                      bir derin doku masaj etkisi sağlayan toplam 24 elektrod
                      bulunur. Bu teknolojiyi diğer tüm teknolojilerden farklı
                      kılan en önemli özellik, bir elektro stimülasyon
                      hissiyatından çok gerçek insan elinin en kusursuz
                      tekniklerle kullanılıyor hissini sağlamasıdır.
                    </Rule>
                  </Col>
                </Row>
              </Box>
            </Box>

            <Box
              mode="padding"
              all={mobile ? 15 : 64}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              width={["xs", "sm", "md", "lg"].includes(screen) ? "100%" : "75%"}
            >
              {/* <Box
                display="flex"
                direction="row"
                align="center"
                justify="center"
                bottom={24}
              >
                <Tabs
                    tabs={["Randevu Al", "İşbirliği"]}
                    active="Randevu Al"
                    press={(tab) => {
                      if (tab === "İşbirliği") {
                        window.location = "/collaboration";
                      }
                    }}
                  />
              </Box> */}
              <ClinicSelect
                value={clinic}
                change={(clinic) => this.setState({ clinic })}
                label="Klinik*"
              />
              <Input
                value={name}
                change={(name) => this.setState({ name })}
                label="Ad Soyad*"
              />
              <Input
                value={email}
                change={(email) => this.setState({ email })}
                label="E-Posta*"
              />
              <Input
                value={phone}
                change={(phone) => this.setState({ phone })}
                label="Telefon Numarası*"
                placeholder={"Örn: +90 555 555 55 55"}
                mask="+99 999 999 9999"
              />
              <Input
                value={message}
                change={(message) => this.setState({ message })}
                label="Mesaj"
              />
              <Input
                value={date}
                change={(date) => this.setState({ date })}
                label="Randevu Tarihi*"
                mask="99/99/9999"
                
                date={true}
              />
              <Checkbox
                bottom={24}
                press={(checked) => this.setState({ checked })}
                title={
                  <>
                    <a target="_blank" href="/post/privacy-policy">
                      KVKK
                    </a>{" "}
                    ve{" "}
                    <a target="_blank" href="/post/privacy-policy">
                      Aydınlatma Metnini
                    </a>{" "}
                    okudum ve kabul ediyorum.
                  </>
                }
                value={checked}
              />
              <Link
            
            to={checked ? '/rendezvous/tesekkurler' : 1 }
          >
           
       
              <Button
                press={checked ? this.send : undefined}
                opacity={checked ? 1 : 0.5}
                width="100%"
                type="primary"
                
              >
                Gönder
              </Button>
              </Link>
            </Box>
          </Box>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}


export default connect(map, dispatcher)(Component);
