import React, { useState, useRef, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";

import { Box, Text, Icon } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = (props) => {
  const {
    size = "medium",
    type = "primary",
    press,
    loading,
    icon,
    left,
    right,
    top,
    bottom,
    feather,
    container,
    border,
  } = props;

  const HEIGHTS = {
    medium: 50,
    large: 57,
  };
  const SPACINGS = {
    medium: 24,
    large: 54,
  };
  const COLORS = {
    white: Colors.black,
    black: Colors.white,
    primary: Colors.white,
  };
  const BACKGROUNDS = {
    white: Colors.white,
    black: Colors.black,
    primary: Colors.primary,
  };
  const BORDERS = {
    white: "",
    black: "2px solid #FFFFFF",
    primary: "",
  };

  const [width, set] = useState(
    typeof props.children === "string" ? props.children.length * 6.3 : 64
  );

  useEffect(() => {
    set(ref.current ? ref.current.offsetWidth - SPACINGS[size] * 2 : 0);
  }, []);

  const ref = useRef(null);

  return (
    <Box
      color="#029ED0"
      height={HEIGHTS[size]}
      left={SPACINGS[size]}
      right={SPACINGS[size]}
      border={BORDERS[type]}
      align="center"
      justify="center"
      display="inline-flex"
      mode="padding"
      direction="row"
      press={loading ? undefined : press}
      style={{ flexShrink: 0 }}
      direct={{ ref }}
      {...props}
    >
      {loading ? (
        <BarLoader
          height={6}
          width={width}
          color={COLORS[type]}
          loading={true}
        />
      ) : (
        <>
          {icon ? (
            <Icon
              feather={feather}
              color={COLORS[type]}
              icon={icon}
              size={16}
              right={props.children ? 8 : 0}
            />
          ) : undefined}
          <Text
            color={COLORS[type]}
            size={16}
            weight="600"
            family="LatoTR"
            line={HEIGHTS[size]}
          >
            {props.children}
          </Text>
        </>
      )}
    </Box>
  );
};

export default Component;
