import React from "react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import Helmet from "react-helmet";
import { Store, Navigator, API } from "./Modules";

import { CookieBanner } from "@palmabit/react-cookie-law";

const App = () => {
  return (
    <Provider store={Store}>
      <Helmet>
        <title>backup Türkiye</title>
      </Helmet>
      <Toaster position="bottom-center" />
      
        <Navigator />
     
      <CookieBanner
        message="Sitemize erişerek çerezlerin bu Politika ile uyumlu şekilde kullanılmasını kabul etmiş olursunuz."
        wholeDomain={true}
        acceptButtonText="Kabul Ediyorum"
        managePreferencesButtonText={null}
        styles={{
          dialog: {
            boxShadow: "0px 1px 4px rgba(10, 15, 13, 0.15)",
            backgroundColor: "white",
            bottom: 16,
            position: "fixed",
            right: 16,
            borderRadius: 16,
            padding: 16,
            width: "calc(100% - 32px)",
            zIndex: 999,
            maxWidth: 360
          },
          policy: {
            marginLeft: 0
          }
        }}
        showDeclineButton={false}
        showPreferencesOption={false}
        showMarketingOption={false}
        showStatisticsOption={false}
        showPreferencesOption={false}
        showPreferencesOption={false}
        showPreferenceOption={false}
        privacyPolicyLinkText="Çerez Politikası"
        policyLink="/post/cookie-policy"
      />
    </Provider>
  );
};

export default App;
