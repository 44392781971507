import React from "react";
import { connect } from "react-redux";

import { MenuContainer, PostContainer, FooterContainer, Degisimbuton } from "../Containers";
import { Box, Rule, Mobile } from "../Bitter";
import { API } from "../Modules";
import { Col, Row } from "react-grid-system";

const Bottom = () => (
  <Mobile
    render={(mobile, minimum, gutter, spacing, screen) => (
      <Box
        mode="padding"
        all={mobile ? 15 : 64}
        style={{ marginLeft: "auto", marginRight: "auto" }}
        width={["xs", "sm", "md", "lg"].includes(screen) ? "100%" : "90%"}
        display="flex"
        direction="column"
      >
        <Box bottom={24}>
          <Row>
            <Col xs={12} sm={12} md={4} style={{ position: "relative" }}>
              <Box
                mode="padding"
                all={16}
                radius={8}
                border="1px solid #ddd"
                height="100%"
              >
                <img width={48} height={48} src="/assets/i/back.png" />
                <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                  Etkili kas geliştirme
                </Rule>
                <Rule rule="Body" display="block">
                  Yumuşak elektriksel uyarılar sinirleri, bağ dokuyu ve kasları
                  hedef alarak sırt kaslarının güçlenmesini sağlar. Ayrıca, tüm
                  vücut üzerinde olumlu etkisi olan endorfinlerin (genellikle
                  mutluluk hormonları olarak adlandırılır) salınmasına neden
                  olurlar.
                </Rule>
              </Box>
            </Col>

            <Col xs={12} sm={12} md={4} style={{ position: "relative" }}>
              <Box
                mode="padding"
                all={16}
                radius={8}
                border="1px solid #ddd"
                height="100%"
              >
                <img src="/assets/i/sun-icon.png" width={36} height={36} />
                <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                  Rahatlatıcı ısı uygulaması
                </Rule>
                <Rule rule="Body" display="block">
                  <span style={{ fontWeight: 700, color: "#029ED0" }}>
                    backup
                  </span>{" "}
                  matı (yatak) 40°C'ye kadar ısıtılabilir. Rahatlatıcı sıcaklık,
                  kas gerginliğiyle savaşmaya yardımcı olur, kan dolaşımını ve
                  metabolizmayı hızlandırır, stresi azaltıcı bir etkiye sahiptir
                  ve yoğun bir yenilenme sağlar.
                </Rule>
              </Box>
            </Col>

            <Col xs={12} sm={12} md={4} style={{ position: "relative" }}>
              <Box
                mode="padding"
                all={16}
                radius={8}
                border="1px solid #ddd"
                height="100%"
              >
                <img src="/assets/i/potion-icon.png" width={36} height={36} />
                <Rule top={24} bottom={12} rule="Subtitle" weight={700}>
                  Kas gevşemesi
                </Rule>
                <Rule rule="Body" display="block">
                  Gerginlik, duruş bozukluğu, stres ve ağrıdan oluşan kısır
                  döngüyü kırmak için tüm omurga kaslarının gevşemesi hayati
                  önem taşır.
                </Rule>
              </Box>
            </Col>
          </Row>
        </Box>
      </Box>
    )}
  />
);

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { post: {} };
  }

  async componentDidMount() {
    const posts = await API.request("/website/posts", {
      parameters: {
        slug: "nasil-kullanilir",
      },
    });
    if (posts.success) {
      if (posts.data.length > 0) {
        this.setState({ posts: posts.data[0] });
      }
    }
  }

  render() {
    const { posts } = this.state;
    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
        identifier="post"
        image="url(/assets/i/skeleton-big.png)"
        style={{
          overflowY: "auto",
          backgroundSize: "cover",
          backgroundPosition: "-200px 0px",
          backgroundAttachment: "fixed",
        }}
      >
        <MenuContainer background="transparent" />
        <PostContainer posts={posts} bottom={<Bottom />} />
        <Degisimbuton />
        <FooterContainer />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
