import React from "react";
import Text from "./Text";
import { Mobile } from "../../Bitter";

const Component = (props) => {
  return (
    <Mobile
      render={(mobile) => {
        const { rule = "Body" } = props;
        return rule === "Display" ? (
          <Text
            display="block"
            size={mobile ? 24 : 48}
            line={mobile ? 32 : 48}
            family="LatoTR"
            weight="600"
            color="#25324B"
            {...props}
          />
        ) : rule === "Header" ? (
          <Text
            display="block"
            size={mobile ? 24 : 48}
            line={mobile ? 36 : 53.8}
            family="LatoTR"
            weight="600"
            color="#25324B"
            {...props}
          />
        ) : rule === "Title" ? (
          <Text
            display="block"
            size={mobile ? 24 : 48}
            line={mobile ? 36 : 53.8}
            family="LatoTR"
            weight="600"
            color="#25324B"
            {...props}
          />
        ) : rule === "Super" ? (
          <Text
            display="block"
            size={mobile ? 24 : 24}
            line={mobile ? 36 : 36}
            family="LatoTR"
            weight="600"
            color="#25324B"
            {...props}
          />
        ) : rule === "Subtitle" ? (
          <Text
            display="block"
            size={mobile ? 18 : 20}
            line={mobile ? 24 : 24}
            family="LatoTR"
            weight="600"
            color="#25324B"
            {...props}
          />
        ) : rule === "Large" ? (
          <Text
            size={mobile ? 18 : 18}
            line={mobile ? 28.8 : 28.8}
            family="LatoTR"
            weight="400"
            color="#25324B"
            display="block"
            {...props}
          />
        ) : rule === "Body" ? (
          <Text
            size={mobile ? 16 : 16}
            line={mobile ? 25.6 : 25.6}
            family="LatoTR"
            weight="400"
            color="#515B6F"
            {...props}
          />
        ) : rule === "Small" ? (
          <Text
            size={mobile ? 12 : 12}
            line={mobile ? 18 : 18}
            family="LatoTR"
            weight="600"
            color="#515B6F"
            {...props}
          />
        ) : (
          <Text {...props} />
        );
      }}
    />
  );
};

export default Component;
