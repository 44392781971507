import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";

import { Box, Rule, Container, Icon, Mobile } from "../Bitter";
import { API } from "../Modules";
import Clinic from "../Bitter/Organisms/Clinic";
import Pagination from "../Bitter/Molecules/Pagination";
import Checkbox from "../Bitter/Molecules/Checkbox";
import Lodash from "lodash";
import { Functions, Colors } from "../Helpers";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      clinicsPerPage: 10,
      filterOpen: true,
      districtFilterOpen: true,
      clinics: [],
      _cities: [],
      _districts: [],
      mobileFilterOpen: false,
    };
  }

  async componentDidMount() {
    const clinics = await API.request("/website/clinics");
    if (clinics.success) {
      this.setState({ clinics: clinics.data });
    }
  }

  render() {
    let clinics = Lodash.filter(this.state.clinics, (clinic) => {
      if (this.state._cities.length > 0) {
        if (this.state._cities.includes(clinic.parameters.city)) {
          if (this.state._districts.length > 0) {
            if (this.state._districts.includes(clinic.parameters.district)) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    });

    clinics.sort((a, b) => {
      let sort = Functions.sort(a.parameters.city, b.parameters.city);
      if (sort === undefined) {
        sort = Functions.sort(a.parameters.district, b.parameters.district);
      }
      return sort;
    });

    let currentClinic = null;

    const cities = [];
    let districts = {};

    this.state.clinics.map((c) => {
      if (!cities.includes(c.parameters.city)) {
        cities.push(c.parameters.city);
      }
      districts[c.parameters.city] = districts[c.parameters.city] || [];
      if (!districts[c.parameters.city].includes(c.parameters.district)) {
        districts[c.parameters.city].push(c.parameters.district);
      }
      districts[c.parameters.city].sort((a, b) => Functions.sort(a, b));
    });

    cities.sort((a, b) => Functions.sort(a, b));

    const { currentPage, clinicsPerPage, filterOpen } = this.state;

    const indexOfLastClinic = currentPage * clinicsPerPage;
    const indexOfFirstClinic = indexOfLastClinic - clinicsPerPage;
    const currentClinics = clinics?.slice(
      indexOfFirstClinic,
      indexOfLastClinic
    );

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });

    const resetPageNumber = () => {
      this.setState({ currentPage: 1 });
    };

    return (
      <Box width="100%">
        <Mobile
          render={(mobile) => (
            <Box
              mode="padding"
              top={mobile ? 15 : 72}
              bottom={mobile ? 15 : 72}
              left={mobile ? 15 : 124}
              right={mobile ? 15 : 123}
            >
              <Row>
                {mobile && this.state.mobileFilterOpen ? (
                  <Box
                    display="flex"
                    width="100%"
                    height="100%"
                    color="white"
                    layer={2}
                    direction="column"
                    position="fixed"
                    mode="position"
                  >
                    <Box
                      display="flex"
                      justify="space-between"
                      align="center"
                      all={15}
                    >
                      <Rule rule="Subtitle">Filtrele</Rule>
                      <Icon
                        color={Colors.black}
                        icon="x"
                        press={() => this.setState({ mobileFilterOpen: false })}
                      />
                    </Box>
                    <Box
                      mode="padding"
                      all={15}
                      display="flex"
                      direction="column"
                      justify="space-between"
                      color="white"
                      style={{ overflowY: "auto" }}
                    >
                      <Box bottom={24}>
                        <Box display="flex" justify="space-between">
                          <Rule
                            weight={700}
                            size={16}
                            line={24}
                            family="LatoTR"
                            color="#25324B"
                          >
                            Şehir
                          </Rule>
                          {filterOpen ? (
                            <Icon
                              icon="chevron-up"
                              color="#25324B"
                              press={() => this.setState({ filterOpen: false })}
                            />
                          ) : (
                            <Icon
                              icon="chevron-down"
                              color="#25324B"
                              press={() => this.setState({ filterOpen: true })}
                            />
                          )}
                        </Box>
                        {filterOpen && cities?.length && (
                          <Box display="flex" direction="column">
                            {cities.map((city) => (
                              <Box display="flex" direction="column">
                                <Checkbox
                                  value={this.state._cities.includes(city)}
                                  press={() => {
                                    let _cities = Lodash.clone(
                                      this.state._cities
                                    );
                                    if (_cities.includes(city)) {
                                      _cities = Lodash.filter(
                                        _cities,
                                        function (_city) {
                                          return _city !== city;
                                        }
                                      );
                                    } else {
                                      _cities.push(city);
                                    }
                                    resetPageNumber();
                                    this.setState({ _cities });
                                  }}
                                  title={city}
                                />
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                      <Box bottom={24}>
                        {this.state._cities.length > 0 ? (
                          <Box>
                            <Box display="flex" justify="space-between">
                              <Rule
                                weight={700}
                                size={16}
                                line={24}
                                family="LatoTR"
                                color="#25324B"
                              >
                                İlçe
                              </Rule>
                              {this.state.districtFilterOpen ? (
                                <Icon
                                  icon="chevron-up"
                                  color="#25324B"
                                  press={() =>
                                    this.setState({ districtFilterOpen: false })
                                  }
                                />
                              ) : (
                                <Icon
                                  icon="chevron-down"
                                  color="#25324B"
                                  press={() =>
                                    this.setState({ districtFilterOpen: true })
                                  }
                                />
                              )}
                            </Box>
                            {this.state._cities.map((_city, _c) => (
                              <Box display="flex" direction="column">
                                {!districts[this.state._cities[_c]]
                                  ? undefined
                                  : districts[this.state._cities[_c]].map(
                                      (district) => (
                                        <Box display="flex" direction="column">
                                          <Checkbox
                                            value={this.state._districts.includes(
                                              district
                                            )}
                                            press={() => {
                                              let _districts = Lodash.clone(
                                                this.state._districts
                                              );
                                              if (
                                                _districts.includes(district)
                                              ) {
                                                _districts = Lodash.filter(
                                                  _districts,
                                                  function (_district) {
                                                    return (
                                                      _district !== district
                                                    );
                                                  }
                                                );
                                              } else {
                                                _districts.push(district);
                                              }
                                              resetPageNumber();
                                              this.setState({ _districts });
                                            }}
                                            title={district}
                                          />
                                        </Box>
                                      )
                                    )}
                              </Box>
                            ))}
                          </Box>
                        ) : undefined}
                      </Box>
                    </Box>
                  </Box>
                ) : undefined}
                {mobile ? undefined : (
                  <>
                    <Col xs={12} sm={12} md={6} lg={3}>
                      <Box bottom={24}>
                        <Box display="flex" justify="space-between">
                          <Rule
                            weight={700}
                            size={16}
                            line={24}
                            family="LatoTR"
                            color="#25324B"
                          >
                            Şehir
                          </Rule>
                          {filterOpen ? (
                            <Icon
                              icon="chevron-up"
                              color="#25324B"
                              press={() => this.setState({ filterOpen: false })}
                            />
                          ) : (
                            <Icon
                              icon="chevron-down"
                              color="#25324B"
                              press={() => this.setState({ filterOpen: true })}
                            />
                          )}
                        </Box>
                        {filterOpen && cities?.length && (
                          <Box display="flex" direction="column">
                            {cities.map((city) => (
                              <Box display="flex" direction="column">
                                <Checkbox
                                  value={this.state._cities.includes(city)}
                                  press={() => {
                                    let _cities = Lodash.clone(
                                      this.state._cities
                                    );
                                    if (_cities.includes(city)) {
                                      _cities = Lodash.filter(
                                        _cities,
                                        function (_city) {
                                          return _city !== city;
                                        }
                                      );
                                    } else {
                                      _cities.push(city);
                                    }
                                    resetPageNumber();
                                    this.setState({ _cities });
                                  }}
                                  title={city}
                                />
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                      {this.state._cities.length > 0 ? (
                        <Box>
                          <Box display="flex" justify="space-between">
                            <Rule
                              weight={700}
                              size={16}
                              line={24}
                              family="LatoTR"
                              color="#25324B"
                            >
                              İlçe
                            </Rule>
                            {this.state.districtFilterOpen ? (
                              <Icon
                                icon="chevron-up"
                                color="#25324B"
                                press={() =>
                                  this.setState({ districtFilterOpen: false })
                                }
                              />
                            ) : (
                              <Icon
                                icon="chevron-down"
                                color="#25324B"
                                press={() =>
                                  this.setState({ districtFilterOpen: true })
                                }
                              />
                            )}
                          </Box>
                          {this.state._cities.map((_city, _c) => (
                            <Box display="flex" direction="column">
                              {!districts[this.state._cities[_c]]
                                ? undefined
                                : districts[this.state._cities[_c]].map(
                                    (district) => (
                                      <Box display="flex" direction="column">
                                        <Checkbox
                                          value={this.state._districts.includes(district)}
                                          press={() => {
                                            let _districts = Lodash.clone(
                                              this.state._districts
                                            );
                                            if (_districts.includes(district)) {
                                              _districts = Lodash.filter(
                                                _districts,
                                                function (_district) {
                                                  return _district !== district;
                                                }
                                              );
                                            } else {
                                              _districts.push(district);
                                            }
                                            resetPageNumber();
                                            this.setState({ _districts });
                                          }}
                                          title={district}
                                        />
                                      </Box>
                                    )
                                  )}
                            </Box>
                          ))}
                        </Box>
                      ) : undefined}
                    </Col>
                  </>
                )}
                <Col xs={12} sm={12} md={6} lg={9}>
                  <Box display="flex" direction="column">
                    <Box bottom={32} display="flex" direction="column">
                      <Box
                        display="flex"
                        align="center"
                        justify="space-between"
                      >
                        <Rule rule="Title">Tüm Merkezler</Rule>
                        {mobile ? (
                          <Box
                            display="inline-flex"
                            align="center"
                            press={() =>
                              this.setState({ mobileFilterOpen: true })
                            }
                          >
                            <Rule
                              display="inline-block"
                              right={8}
                              rule="Body"
                              color={Colors.primary}
                            >
                              Şehir Seçin
                            </Rule>
                            <Icon color={Colors.primary} icon="filter" />
                          </Box>
                        ) : undefined}
                      </Box>
                      <Rule>
                        Toplam {clinics?.length} merkezimiz bulunuyor.
                      </Rule>
                    </Box>
                    <Box>
                      {currentClinics?.map((clinic, index) => (
                        <Clinic
                          mobile={mobile}
                          key={clinic.uuid}
                          name={clinic.parameters.name}
                          city={clinic.parameters.city}
                          district={clinic.parameters.district}
                          address={clinic.parameters.address}
                          phone={clinic.parameters.phone}
                          email={clinic.parameters.email}
                          rendezvous={() => {
                            const { history } = this.props;
                            history.push("/rendezvous", {
                              clinic: clinic.uuid,
                            });
                          }}
                        />
                      ))}
                    </Box>
                    <Box>
                      <Pagination
                        itemsPerPage={clinicsPerPage}
                        totalItems={clinics?.length}
                        currentPage={currentPage}
                        paginate={paginate}
                      />
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
        />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
