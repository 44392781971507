import React from "react";
import { Link } from "react-router-dom";
import { Box, Icon, Rule } from "..";
import { Colors, Functions } from "../../Helpers";

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const itemNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  Functions.setPageNumbers(itemNumbers, currentPage, totalPages);

  return (
    <Box display="flex" align="center" justify="center">
      <Icon
        press={() => {
          currentPage - 1 > 0 && paginate(currentPage - 1);
        }}
        icon="chevron-left"
        color="#25324B"
        style={{ marginRight: 8 }}
      />
      {itemNumbers.map((number) => (
        <Box
          key={number}
          radius={8}
          color={currentPage === number ? Colors.primary : "#FFFFFF"}
          mode="padding"
          top={10}
          bottom={10}
          left={12}
          right={12}
          height={46}
          width={46}
          display="flex"
          align="center"
          justify="center"
          press={() => paginate(number)}
        >
          <Rule
            rule="Body"
            color={currentPage === number ? Colors.white : "#515B6F"}
          >
            {number}
          </Rule>
        </Box>
      ))}
      {!itemNumbers.includes(totalPages) && (
        <>
          <Box mode="padding" top={10} bottom={10} left={12} right={12}>
            <Rule rule="Body" color="#515B6F">
              ...
            </Rule>
          </Box>
          <Box
            key={totalPages}
            radius={8}
            color={currentPage === totalPages ? Colors.primary : "#FFFFFF"}
            mode="padding"
            top={10}
            bottom={10}
            left={12}
            right={12}
            height={46}
            width={46}
            display="flex"
            align="center"
            justify="center"
            press={() => paginate(totalPages)}
          >
            <Rule
              rule="Body"
              color={currentPage === totalPages ? Colors.white : "#515B6F"}
            >
              {totalPages}
            </Rule>
          </Box>
        </>
      )}
      <Icon
        press={() => {
          currentPage + 1 <= Math.ceil(totalItems / itemsPerPage) &&
            paginate(currentPage + 1);
        }}
        icon="chevron-right"
        color="#25324B"
        style={{ marginLeft: 8 }}
      />
    </Box>
  );
};

export default Pagination;
