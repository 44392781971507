import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";

import { Box, Rule, Container, Mobile, Icon } from "../Bitter";
import Checkbox from "../Bitter/Molecules/Checkbox";
import Pagination from "../Bitter/Molecules/Pagination";
import Expert from "../Bitter/Organisms/Expert";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      expertsPerPage: 3,
      filterOpen: false,
    };
  }

  render() {
    const { experts, cities } = this.props;
    const { currentPage, expertsPerPage, filterOpen } = this.state;

    const indexOfLastExpert = currentPage * expertsPerPage;
    const indexOfFirstExpert = indexOfLastExpert - expertsPerPage;
    const currentExperts = experts?.slice(
      indexOfFirstExpert,
      indexOfLastExpert
    );

    console.log(currentExperts);

    const paginate = (pageNumber) => this.setState({ currentPage: pageNumber });
    return (
      <Box width="100%">
        <Mobile
          render={(mobile) => (
            <Box
              mode="padding"
              top={mobile ? 15 : 72}
              bottom={mobile ? 15 : 72}
              left={mobile ? 15 : 124}
              right={mobile ? 15 : 123}
            >
              <Row>
                <Col xs={12} sm={12} md={6} lg={3}>
                  <Box display="flex" justify="space-between">
                    <Rule
                      family="LatoTR"
                      color="#25324B"
                    >
                      Şehir
                    </Rule>
                    {filterOpen ? (
                      <Icon
                        icon="chevron-up"
                        color="#25324B"
                        press={() => this.setState({ filterOpen: false })}
                      />
                    ) : (
                      <Icon
                        icon="chevron-down"
                        color="#25324B"
                        press={() => this.setState({ filterOpen: true })}
                      />
                    )}
                  </Box>
                  {filterOpen && cities?.length && (
                    <Box display="flex" direction="column">
                      {cities.map((city) => (
                        <Box display="flex" direction="column">
                          <Checkbox title={city} />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={9}>
                  <Box display="flex" direction="column">
                    <Box bottom={32} display="flex" direction="column">
                      <Rule rule="Title">
                        Uzmanlar
                      </Rule>
                      <Rule>Toplam {experts?.length} uzmanımız buluyor</Rule>
                    </Box>
                    <Box>
                      {currentExperts?.map((expert, index) => (
                        <Expert
                          mobile={mobile}
                          key={expert.uuid}
                          name={expert.parameters.name}
                          image={expert.parameters.image}
                          profession={expert.parameters.profession}
                          city={expert.parameters.city}
                          phone={expert.parameters.phone}
                          email={expert.parameters.email}
                        />
                      ))}
                    </Box>
                    <Box>
                      <Pagination
                        itemsPerPage={expertsPerPage}
                        totalItems={experts?.length}
                        currentPage={currentPage}
                        paginate={paginate}
                      />
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
        />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
