import React from "react";
import { connect } from "react-redux";

import {
  Box,
  Rule,
  Button,
  Mobile,
  Icon,
  Container,
  Editor,
  ClinicSelect,
  Input,
} from "../Bitter";
import { Colors, Resources, Functions } from "../Helpers";
import { MenuContainer } from "../Containers";
import { API } from "../Modules";
import { Row, Col } from "react-grid-system";

import ReactSiema from "siema-react";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      clinic: "",
      clinics: [],
      page: 0,
      sliders: [],
      editor: true,
    };
  }

  send = () => {
    const { clinic, name } = this.state;
    const { history } = this.props;
    history.push("/rendezvous", { clinic, name });
  };

  async componentDidMount() {
    const sliders = await API.request("/@sliders/read");
    if (sliders.success) {
      this.setState({ sliders: sliders.data });
    }

    const clinics = await API.request("/website/clinics");
    if (clinics.success) {
      this.setState({ clinics: clinics.data });
    }

    return;

    setInterval(async () => {
      this.setState({
        page:
          this.state.page === this.state.sliders.length - 1
            ? 0
            : this.state.page + 1,
      });
      if (this.reference) {
        this.reference.changeData(
          this.state.sliders[
            this.state.page === this.state.sliders.length - 1
              ? 0
              : this.state.page + 1
          ].parameters.content
        );
        this.forceUpdate();
      }
    }, 4000);
  }

  render() {
    const { clinic, name, clinics, page, sliders, editor } = this.state;

    const BLOCKS =
      sliders.length > 0
        ? sliders[page].parameters.content.blocks.length > 0
        : false;

    return (
      <Mobile
        render={(mobile, minimum, gutter, spacing, screen, width) =>
          mobile ? (
            <>
              <MenuContainer background="white" />
              <Box position="relative">
                <Box
                  styling="--slider"
                  position="relative"
                  style={{
                    overflow: "hidden",
                    backgroundImage:
                      sliders.length > 0
                        ? "url(" +
                          Resources.url +
                          "/uploads/preview/" +
                          (sliders[page].parameters.mobile
                            ? sliders[page].parameters.mobile
                            : sliders[page].parameters.attachment) +
                          ".jpg" +
                          ")"
                        : null,
                    minHeight: "calc(46.5789vw)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <Box
                    position="absolute"
                    right="unset"
                    bottom="unset"
                    left={24}
                    top="50%"
                    mode="position"
                    style={{ transform: "translateY(-50%)" }}
                  >
                    <Box display="flex" justify="center">
                      <Icon
                        icon="chevron-left"
                        size={36}
                        color="#029ed0"
                        opacity={0.5}
                        press={() => {
                          if (this.state.page === 0) {
                            this.setState({
                              page: this.state.sliders.length - 1,
                            });
                            if (this.reference) {
                              if (this.reference) {
                                this.reference.changeData(
                                  this.state.sliders[this.state.page].parameters
                                    .content
                                );
                                this.forceUpdate();
                              }
                              this.forceUpdate();
                            }
                          } else {
                            this.setState({ page: this.state.page - 1 });
                            if (this.reference) {
                              if (this.reference) {
                                this.reference.changeData(
                                  this.state.sliders[this.state.page].parameters
                                    .content
                                );
                                this.forceUpdate();
                              }
                              this.forceUpdate();
                            }
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    left="unset"
                    bottom="unset"
                    right={24}
                    top="50%"
                    mode="position"
                    style={{ transform: "translateY(-50%)" }}
                  >
                    <Box display="flex" justify="center">
                      <Icon
                        icon="chevron-right"
                        size={36}
                        color="#029ed0"
                        opacity={0.5}
                        press={() => {
                          if (
                            this.state.page ===
                            this.state.sliders.length - 1
                          ) {
                            this.setState({
                              page: 0,
                            });
                            if (this.reference) {
                              this.reference.changeData(
                                this.state.sliders[this.state.page].parameters
                                  .content
                              );
                              this.forceUpdate();
                            }
                          } else {
                            this.setState({ page: this.state.page + 1 });
                            if (this.reference) {
                              this.reference.changeData(
                                this.state.sliders[this.state.page].parameters
                                  .content
                              );
                              this.forceUpdate();
                            }
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box color="white" mode="padding" all={15}>
                  <Container
                    render={(mobile) => (
                      <Box display="flex" top={0} bottom={0} mode="padding">
                        <Box
                          width="100%"
                          display="flex"
                          direction="column"
                          position="relative"
                          styling="--editor-mobile"
                        >
                          {BLOCKS ? (
                            <Box
                              style={{
                                transform:
                                  "scale(" +
                                  (mobile
                                    ? 1
                                    : width / 1140 > 1
                                    ? 1
                                    : width / 1140) +
                                  ")",
                              }}
                            >
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={8} xl={7}>
                                  {sliders.length > 0 ? (
                                    <Editor
                                      reference={(reference) =>
                                        (this.reference = reference)
                                      }
                                      value={sliders[page].parameters.content}
                                    />
                                  ) : undefined}
                                </Col>
                              </Row>
                            </Box>
                          ) : undefined}
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={10} xl={8}>
                              <Box
                                color={Colors.white}
                                display="flex"
                                justify="space-between"
                                mode="padding"
                                direction={mobile ? "column" : "row"}
                                all={16}
                              >
                                <Box
                                  display="flex"
                                  align="center"
                                  mode="padding"
                                  left={16}
                                  right={16}
                                  width="100%"
                                >
                                  <Icon
                                    icon="search"
                                    color={Colors.black}
                                    right={16}
                                  />
                                  <Input
                                    placeholder="Adınız Soyadınız"
                                    value={name}
                                    change={(name) => this.setState({ name })}
                                    bottom={12}
                                  />
                                </Box>
                                <Box
                                  display="flex"
                                  justify="space-between"
                                  align="center"
                                  mode="padding"
                                  right={16}
                                  left={16}
                                  width="100%"
                                >
                                  <Icon
                                    icon="map-pin"
                                    color={Colors.Black}
                                    style={{ marginRight: 16 }}
                                  />
                                  <ClinicSelect
                                    change={(clinic) =>
                                      this.setState({ clinic })
                                    }
                                  />
                                </Box>
                                <Box
                                  width="100%"
                                  mode="padding"
                                  top={mobile ? 14 : 0}
                                >
                                  <Button
                                    press={this.send}
                                    type="primary"
                                    size="large"
                                    width="100%"
                                  >
                                    Randevu Al
                                  </Button>
                                </Box>
                              </Box>
                            </Col>
                          </Row>
                        </Box>
                      </Box>
                    )}
                  />
                  <Box display="flex" justify="center">
                    {sliders.map((s, ss) => (
                      <Box
                        press={async () => {
                          this.setState({ page: ss });
                          if (this.reference) {
                            this.reference.changeData(s.parameters.content);
                          }
                          this.forceUpdate();
                        }}
                        radius="50%"
                        color={this.state.page === ss ? "#029ed0" : "#029ed0"}
                        width={8}
                        height={8}
                        opacity={this.state.page !== ss && 0.2}
                        right={8}
                      ></Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <MenuContainer background="white" />
              <Box position="relative">
                <Box
                  styling="--slider"
                  style={{
                    overflow: "hidden",
                    backgroundImage:
                      sliders.length > 0
                        ? "url(" +
                          Resources.url +
                          "/uploads/preview/" +
                          sliders[page].parameters.attachment +
                          ".jpg" +
                          ")"
                        : null,
                    minHeight: "calc(46.5789vw)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <Box
                    style={{
                      overflow: "hidden",
                      backgroundSize: "cover",
                      backgroundPosition: "-200px -350px",
                      minHeight: "calc(46.5789vw)",
                    }}
                    image={BLOCKS ? "url(/assets/i/background.svg)" : undefined}
                  >
                    <Container
                      style={{
                        minHeight: "calc(46.5789vw)",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                      render={(mobile) => (
                        <Box
                          display="flex"
                          top={82}
                          bottom={103}
                          mode="padding"
                        >
                          <Box
                            width="100%"
                            display="flex"
                            direction="column"
                            position="relative"
                          >
                            {BLOCKS ? (
                              <Box
                                width="80%"
                                color={Colors.primary}
                                radius={999}
                                position="absolute"
                                left="-50%"
                                bottom="unset"
                                mode="position"
                                top="100%"
                                right="unset"
                                opacity={0.2}
                                style={{
                                  transform: "translateY(-50%)",
                                  paddingTop: "80%",
                                }}
                              />
                            ) : undefined}
                            {BLOCKS ? (
                              <Box
                                style={{
                                  transform:
                                    "scale(" +
                                    (mobile
                                      ? 1
                                      : width / 1140 > 1
                                      ? 1
                                      : width / 1140) +
                                    ")",
                                }}
                              >
                                <Row>
                                  <Col xs={12}>
                                    {sliders.length > 0 ? (
                                      <Editor
                                        reference={(reference) =>
                                          (this.reference = reference)
                                        }
                                        value={sliders[page].parameters.content}
                                      />
                                    ) : undefined}
                                  </Col>
                                </Row>
                              </Box>
                            ) : undefined}
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={10} xl={12}>
                                <Box
                                  color={Colors.white}
                                  display="flex"
                                  justify="space-between"
                                  mode="padding"
                                  direction={mobile ? "column" : "row"}
                                  all={16}
                                  width="100%"
                                  align="center"
                                  style={{ width: "100%" }}
                                >
                                  <Box
                                    display="flex"
                                    justify="space-between"
                                    align="center"
                                    mode="padding"
                                    right={16}
                                    left={16}
                                    width="100%"
                                  >
                                    <Icon
                                      icon="map-pin"
                                      color={Colors.Black}
                                      style={{ marginRight: 16 }}
                                    />
                                    <ClinicSelect
                                      change={(clinic) =>
                                        this.setState({ clinic })
                                      }
                                      bottom={0}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    align="center"
                                    mode="padding"
                                    left={16}
                                    right={16}
                                    width="100%"
                                  >
                                    <Icon
                                      icon="search"
                                      color={Colors.black}
                                      right={16}
                                    />
                                    <Input
                                      placeholder="Adınız Soyadınız"
                                      value={name}
                                      change={(name) => this.setState({ name })}
                                      bottom={0}
                                    />
                                  </Box>
                                  <Button
                                    press={this.send}
                                    type="primary"
                                    size="large"
                                  >
                                    Randevu Al
                                  </Button>
                                </Box>
                              </Col>
                            </Row>
                          </Box>
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  bottom={48}
                  top="unset"
                  right="unset"
                  left="50%"
                  mode="position"
                  style={{ transformX: "translate(-50%)" }}
                >
                  <Box display="flex" justify="center">
                    {sliders.map((s, ss) => (
                      <Box
                        press={async () => {
                          this.setState({ page: ss });
                          if (this.reference) {
                            this.reference.changeData(s.parameters.content);
                          }
                          this.forceUpdate();
                        }}
                        radius="50%"
                        color={this.state.page === ss ? "#029ed0" : "#029ed0"}
                        width={8}
                        height={8}
                        opacity={this.state.page !== ss && 0.2}
                        right={8}
                      ></Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  right="unset"
                  bottom="unset"
                  left={24}
                  top="40%"
                  mode="position"
                  style={{ transform: "translateY(-50%)" }}
                >
                  <Box display="flex" justify="center">
                    <Icon
                      icon="chevron-left"
                      size={48}
                      color="#029ed0"
                      opacity={1}
                      press={() => {
                        if (this.state.page === 0) {
                          this.setState({
                            page: this.state.sliders.length - 1,
                          });
                          if (this.reference) {
                            if (this.reference) {
                              this.reference.changeData(
                                this.state.sliders[this.state.page].parameters
                                  .content
                              );
                              this.forceUpdate();
                            }
                            this.forceUpdate();
                          }
                        } else {
                          this.setState({ page: this.state.page - 1 });
                          if (this.reference) {
                            if (this.reference) {
                              this.reference.changeData(
                                this.state.sliders[this.state.page].parameters
                                  .content
                              );
                              this.forceUpdate();
                            }
                            this.forceUpdate();
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  left="unset"
                  bottom="unset"
                  right={24}
                  top="40%"
                  mode="position"
                  style={{ transform: "translateY(-50%)" }}
                >
                  <Box display="flex" justify="center">
                    <Icon
                      icon="chevron-right"
                      size={48}
                      color="#029ed0"
                      opacity={1}
                      press={() => {
                        if (this.state.page === this.state.sliders.length - 1) {
                          this.setState({
                            page: 0,
                          });
                          if (this.reference) {
                            this.reference.changeData(
                              this.state.sliders[this.state.page].parameters
                                .content
                            );
                            this.forceUpdate();
                          }
                        } else {
                          this.setState({ page: this.state.page + 1 });
                          if (this.reference) {
                            this.reference.changeData(
                              this.state.sliders[this.state.page].parameters
                                .content
                            );
                            this.forceUpdate();
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )
        }
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
