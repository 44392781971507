import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { SocialIcon } from "react-social-icons";

import { Link } from "react-router-dom";

import { Box, Rule, Container, Button } from "../Bitter";
import { Colors } from "../Helpers";

const references = [
  { image: "florence-resized", url: "https://www.florence.com.tr" },
  {
    image: "amerikanhastanesi-resized",
    url: "https://www.amerikanhastanesi.org/",
  },
  { image: "acibadem-resized", url: "https://www.acibadem.com.tr" },
  { image: "medicana-resized", url: "https://www.medicana.com.tr" },
  { image: "medicalpark-resized", url: "https://www.medicalpark.com.tr" },
  { image: "avicenna-hastanesi-resized", url: "https://avicennaint.com" },
  { image: "fizyoform-resized", url: "https://www.fizyoform.com" },
  { image: "sportomed-resized", url: "https://sportomed.com.tr" },
  
];

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
    };
  }

  render() {
    const { name } = this.state;

    return (
      <>
        <Box
          width="100%"
          color={Colors.white}
          mode="padding"
          top={48}
          bottom={24}
          identifier="references"
        >
          <Container
            render={(mobile) => (
              <>
                <Rule rule="Super" display="block" bottom={24}>
                  Referanslarımız
                </Rule>
                <Box
                  display="flex"
                  style={{
                    flexWrap: "wrap",
                    marginLeft: -15,
                    marginRight: -15,
                  }}
                  align="center"
                  justify="center"
                >
                  {references.map((r) => (
                    <a target="_blank" href={r.url} style={{ marginBottom: 6 }}>
                      <img
                        style={{
                          height: 56,
                          width: "auto",
                          display: "block",
                          marginRight: 10,
                          marginLeft: 10,
                        }}
                        src={"/assets/i/sponsors/" + r.image + ".png"}
                      />
                    </a>
                  ))}
                </Box>
              </>
            )}
          />
        </Box>
        <Box
          width="100%"
          color={Colors.space}
          mode="padding"
          top={16}
          bottom={16}
        >
          <Container
            render={(mobile) => (
              <>
                <Row>
                  <Col>
                    <Box
                      bottom={16}
                      direction={mobile ? "column" : "row"}
                      display="flex"
                      justify="space-between"
                    >
                      <Box display="flex" align="flex-start" direction="column">
                        <a href="/">
                          <img
                            src="/assets/i/logo--sanus-light.png"
                            height="48"
                            width="auto"
                            display="block"
                          />
                        </a>
                        <Rule
                          left={0}
                          bottom={12}
                          display="inline-block"
                          color="#909090"
                          weight={500}
                          rule="Body"
                        >
                          2022 @ Backup Omurga Rehabilitasyon Teknolojisi tek
                          yetkili Türkiye distribütörü Sanus Sağlıklı Yaşam
                          Teknolojileri A.Ş.’dir.
                        </Rule>
                      </Box>

                      <Box display="flex">
                        <SocialIcon
                          url="https://www.facebook.com/backupturkey"
                          network="facebook"
                          style={{ height: 32, width: 32, marginRight: 24 }}
                          bgColor="rgba(255,255,255,0.15)"
                          fgColor={Colors.white}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                        <SocialIcon
                          url="https://www.instagram.com/backupturkey/"
                          network="instagram"
                          style={{ height: 32, width: 32, marginRight: 24 }}
                          bgColor="rgba(255,255,255,0.15)"
                          fgColor={Colors.white}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                        <SocialIcon
                          url="https://www.linkedin.com/company/sanus-teknoloji"
                          network="linkedin"
                          style={{ height: 32, width: 32, marginRight: 24 }}
                          bgColor="rgba(255,255,255,0.15)"
                          fgColor={Colors.white}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      </Box>
                    </Box>
                    <Box display="flex" direction={mobile ? "column" : "row"}>
                      <Rule
                        bottom={12}
                        right={12}
                        display="inline-block"
                        rule="Menu"
                        color={Colors.white}
                        to="/post/privacy-policy"
                        opacity={0.5}
                        target="_blank"
                      >
                        KVKK
                      </Rule>
                      <Rule
                        bottom={12}
                        right={12}
                        display="inline-block"
                        rule="Menu"
                        color={Colors.white}
                        to="/post/terms-of-use"
                        opacity={0.5}
                        target="_blank"
                      >
                        Aydınlatma Metni
                      </Rule>
                      <Rule
                        bottom={12}
                        right={12}
                        display="inline-block"
                        rule="Menu"
                        color={Colors.white}
                        to="/post/cookie-policy"
                        opacity={0.5}
                        target="_blank"
                      >
                        Çerez Politikası
                      </Rule>
                    </Box>
                  </Col>
                </Row>
              </>
            )}
          />
        </Box>
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
