import React from "react";
import { connect } from "react-redux";

import { TemporaryContainer } from "../Containers";
import { Box } from "../Bitter";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      ></Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
