import React, { useState, useEffect } from "react";

import Editor from "./Editor";
import { API } from "../../Modules";
import Lodash from "lodash";

const _ = () => {
  return {};
};

const Static = ({ render = false, slug }) => {
  const [value, set] = useState(false);

  useEffect(async () => {
    /* if (sessionStorage) {
      if (sessionStorage.getItem("@statics-" + slug)) {
        set(JSON.parse(sessionStorage.getItem("@statics-" + slug)));
      }
    }*/

    const data = await API.request("/@statics/read", {
      parameters: {
        slug,
      },
    });

    if (data.success) {
      if (data.data.length > 0) {
        let temporary = {};
        temporary = Lodash.clone(data.data[0].parameters.content);
        temporary.blocks = [];
        data.data[0].parameters.content.blocks.map((block) => {
          if (block.type != "image") {
            temporary.blocks.push(block);
          }
        });

        set(temporary);
        /* sessionStorage.setItem("@statics-" + slug, JSON.stringify(temporary)); */
      }
    }
  }, []);

  if (render && value) {
    return render(value);
  }

  if (value) {
    return <Editor value={value} />;
  }

  return null;
};

export default Static;
