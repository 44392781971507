import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";

import { Box, Rule, Container, Blog } from "../Bitter";
import { API } from "../Modules";
import { Resources } from "../Helpers";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  async componentDidMount() {
    const { data } = await API.request("/website/posts", {
      parameters: {
        type: "blog",
      },
      order: "DESC",
    });
    this.setState({ posts: data });
  }

  render() {
    return (
      <Box width="100%">
        <Container
          render={(mobile) => (
            <>
              <Box
                mode="padding"
                top={74}
                bottom={74}
                display="flex"
                direction="column"
              >
                {Resources.displayBlogsTitle ? (
                  <Rule rule="Display" bottom={48}>
                    Yazılar
                  </Rule>
                ) : undefined}
                <Row>
                  {this.state.posts.map((post, index) => (
                    <Col
                      key={post.parameters.uuid}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      style={{ marginBottom: 48, position: "relative" }}
                    >
                      <Blog
                        debug={true}
                        title={post.parameters.title}
                        description={post.parameters.description}
                        date={post.parameters.date}
                        attachment={post.parameters.attachment}
                        slug={post.parameters.slug}
                      />
                    </Col>
                  ))}
                </Row>
              </Box>
            </>
          )}
        />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
