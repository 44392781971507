import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  BlogsScreen,
  ClinicsScreen,
  CollaborationScreen,
  ExpertsScreen,
  HomeScreen,
  PostScreen,
  RendezvousScreen,
  FontsScreen,
  HowToPostScreen
} from "../Screens";

class Component extends React.PureComponent {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/blog" component={BlogsScreen} />
          <Route exact path="/clinics" component={ClinicsScreen} />
          <Route exact path="/collaboration" component={CollaborationScreen} />
          <Route exact path="/collaboration/tesekkurler" component={CollaborationScreen} />
          <Route exact path="/experts" component={ExpertsScreen} />
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/post/nasil-kullanilir" component={HowToPostScreen} />
          <Route exact path="/post/:post" component={PostScreen} />
          <Route exact path="/rendezvous" component={RendezvousScreen} />
          <Route exact path="/rendezvous/tesekkurler" component={RendezvousScreen} />

          <Route exact path="/fonts" component={FontsScreen} />
        </Switch>
      </Router>
    );
  }
}

export default Component;
