import React from "react";
import { connect } from "react-redux";

import { MenuContainer, PostContainer, FooterContainer, Degisimbuton } from "../Containers";
import { Box } from "../Bitter";
import { API } from "../Modules";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { post: {} };
  }

  async componentDidMount() {
    const posts = await API.request("/website/posts", {
      parameters: {
        slug: this.props.match.params.post,
      },
    });
    if (posts.success) {
      if (posts.data.length > 0) {
        this.setState({ posts: posts.data[0] });
      }
    }
  }

  render() {
    const { posts } = this.state;
    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
        identifier="post"
        image="url(/assets/i/skeleton-big.png)"
        style={{
          overflowY: "auto",
          backgroundSize: "cover",
          backgroundPosition: "-200px 0px",
          backgroundAttachment: "fixed",
        }}
      >
        <MenuContainer background="transparent" />
        <PostContainer posts={posts} />
        <Degisimbuton />
        <FooterContainer />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
