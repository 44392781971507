import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Container, Blog } from "../Bitter";
import { Col, Row } from "react-grid-system";
import { Colors } from "../Helpers";
import { API } from "../Modules";
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  async componentDidMount() {
    const posts = await API.request("/website/posts", {
      limit: 4,
      order: "DESC",
      parameters: {
        type: "blog",
      },
    });
    if (posts.success) {
      this.setState({ posts: posts.data });
    }
  }

  render() {
    const { posts } = this.state;

    return (
      <Box width="100%" color={Colors.white} mode="padding" bottom={72}>
        <Container
          render={(mobile) => (
            <>
              <Box
                display="flex"
                justify="space-between"
                align="flex-end"
                bottom={48}
              >
                <Rule rule="Title">Blog</Rule>
                <Box display="flex" justify="flex-end" align="center">
                  <Rule rule="Body" color={Colors.primary} weight={600}>
                    <Link
                      to="/blog"
                      style={{
                        textDecoration: "none",
                        color: Colors.primary,
                      }}
                    >
                      Tüm yazılar
                    </Link>
                  </Rule>
                  <FeatherIcon
                    style={{ marginLeft: 15 }}
                    color={Colors.primary}
                    size={22}
                    icon="arrow-right"
                  />
                </Box>
              </Box>
              <Row>
                {posts.map((post) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    key={post.uuid}
                    style={{ position: "relative" }}
                  >
                    <Blog
                      debug={true}
                      slug={post.parameters.slug}
                      title={post.parameters.title}
                      date={post.parameters.date}
                      attachment={post.parameters.attachment}
                      description={post.parameters.description}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
