import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { Rule } from "../Bitter";



export default function Degisimbuton() {
  return (
    <div className="Deneme2">
      <div className="Deneme3">
      <h1>DAHA FAZLA BİLGİ ALMAK İÇİN</h1>
      </div>
      <div  className="onecikan">
       
      <Rule
            to={`/collaboration#hemenbasvur`}
            
          >
        <button 
        
        className="mavi">Hemen Başvur</button>
       </Rule>
      </div>
    </div>
  );
    
  }
