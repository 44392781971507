import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Container, Mobile, Icon, Input, Button } from "../Bitter";

import { Link } from "react-router-dom";
import { Colors, Resources } from "../Helpers";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    const { background = "#FFFFFF" } = this.props;
    const { open } = this.state;

    return (
      <Box color={background}>
        <Mobile
          render={(mobile) =>
            mobile ? null : (
              <Box color={background} height={48}>
                <Container
                  render={(mobile) => (
                    <Box display="flex" justify="space-between">
                      <Box top={12} display="flex">
                        <Box display="flex" align="center">
                          <Icon
                            right={12}
                            size={16}
                            icon="check"
                            color="#029ED0"
                            weight={3}
                          />
                          <Rule right={24} rule="Body" size={13} weight={700}>
                            ALMANYA'DA ÜRETİLDİ
                          </Rule>
                        </Box>

                        <Box display="flex" align="center">
                          <Icon
                            right={12}
                            size={16}
                            icon="check"
                            color="#029ED0"
                            weight={3}
                          />
                          <Rule right={24} rule="Body" size={13} weight={700}>
                            DOĞRUDAN ÜRETİCİDEN
                          </Rule>
                        </Box>
                      </Box>

                      <Box top={12} display="flex" align="center">
                        <Rule
                          rule="Body"
                          size={13}
                          weight={700}
                          to={"tel:" + Resources.phone}
                        >
                          {Resources.phone}
                        </Rule>
                      </Box>
                    </Box>
                  )}
                />
              </Box>
            )
          }
        />

        <Mobile
          render={(mobile) =>
            open && mobile ? (
              <Box
                display="flex"
                width="100%"
                height="100%"
                color="#EFFBFA"
                layer={2}
                direction="column"
                position="fixed"
              >
                <Box
                  display="flex"
                  justify="space-between"
                  align="center"
                  all={15}
                >
                  <a href="/">
                    <img
                      src="/assets/i/backup-dark-logo.png"
                      height="48"
                      width="auto"
                      display="block"
                    />
                  </a>
                  <Icon
                    color={Colors.black}
                    icon="x"
                    press={() => this.setState({ open: !open })}
                  />
                </Box>

                <Box
                  flex={1}
                  mode="padding"
                  all={15}
                  display="flex"
                  direction="column"
                  justify="space-between"
                  color={background}
                >
                  <Box
                    color={background}
                    width="100%"
                    height="auto"
                    display="inline-flex"
                    direction="column"
                  >
                    <Box bottom={19} display="flex" align="center">
                      <Rule
                        rule="Large"
                        weight={700}
                        to={"tel:" + Resources.phone}
                      >
                        {Resources.phone}
                      </Rule>
                    </Box>
                    <Rule
                      color="#515B6F"
                      to="/post/hakkimizda"
                      rule="Large"
                      bottom={19}
                    >
                      Nedir?
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="/post/nasil-kullanilir"
                      rule="Large"
                      bottom={19}
                    >
                      Nasıl Kullanılır?
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="/post/etkinlik"
                      rule="Large"
                      bottom={19}
                    >
                      Etkinlik
                    </Rule>

                    <Rule color="#515B6F" to="/blog" rule="Large" bottom={19}>
                      Blog
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="/post/s-s-s"
                      rule="Large"
                      bottom={19}
                    >
                      S.S.S
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="/clinics"
                      rule="Large"
                      bottom={19}
                    >
                      Merkezler
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="/rendezvous"
                      rule="Large"
                      bottom={19}
                    >
                      Randevu Al
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="/collaboration"
                      rule="Large"
                      bottom={19}
                    >
                      İş Ortaklığı
                    </Rule>

                    <Rule
                      color="#515B6F"
                      to="#references"
                      rule="Large"
                      bottom={19}
                      press={() => {
                        window.location = "#references";
                        this.setState({ open: false });
                      }}
                    >
                      Referanslarımız
                    </Rule>
                  </Box>
                  {/*<Box
                    color="white"
                    width="100%"
                    height="auto"
                    display="inline-flex"
                    direction="column"
                    justify="space-between"
                    mode="padding"
                    all={16}
                  >
                    <Box
                      display="flex"
                      align="center"
                      mode="padding"
                      top={20}
                      style={{ marginBottom: 12 }}
                    >
                      <Icon
                        icon="search"
                        color={Colors.black}
                        style={{ marginRight: 16 }}
                      />
                      <input
                        placeholder="Ad Soyad"
                        style={{
                          width: "100%",
                          border: 0,
                          outline: 0,
                          background: "transparent",
                          borderBottom: "1px solid #D6DDEB",
                          paddingTop: 20,
                          paddingBottom: 11,
                        }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justify="space-between"
                      align="center"
                      mode="padding"
                      top={20}
                      style={{ marginBottom: 12 }}
                    >
                      <Icon
                        icon="map-pin"
                        color={Colors.Black}
                        style={{ marginRight: 16 }}
                      />
                      <select
                        style={{
                          width: "100%",
                          paddingTop: 20,
                          paddingBottom: 11,
                          border: 0,
                          outline: 0,
                          borderBottom: "1px solid #D6DDEB",
                        }}
                        placeholder="Merkez"
                      >
                        <option value="" disabled selected>
                          Merkez Seç
                        </option>
                      </select>
                    </Box>
                    <Box>
                      <Button
                        width="100%"
                        type="primary"
                      >
                        Randevu Al
                      </Button>
                    </Box>
                  </Box>*/}
                </Box>
              </Box>
            ) : mobile && !open ? (
              <Box
                display="flex"
                justify="space-between"
                align="center"
                top={15}
                bottom={15}
              >
                <a href="/">
                  <img
                    style={{
                      marginLeft: 15,
                      width: "auto",
                      height: 48,
                      display: "block",
                    }}
                    src="/assets/i/backup-dark-logo.png"
                  />
                </a>
                <Icon
                  right={15}
                  color={Colors.black}
                  icon="menu"
                  press={() => this.setState({ open: !open })}
                />
              </Box>
            ) : undefined
          }
        />

        <Box width="100%" color={background}>
          <Container
            render={(mobile) =>
              !mobile ? (
                <Box display="flex" direction="column">
                  <Box
                    display="flex"
                    justify="space-between"
                    align="center"
                    top={15}
                    bottom={15}
                  >
                    <Box display="flex" justify="space-between" align="center">
                      <a href="/">
                        <img
                          src="/assets/i/backup-dark-logo.png"
                          width="auto"
                          height="48"
                          display="block"
                        />
                      </a>

                      <Rule
                        to="/post/hakkimizda"
                        rule="Body"
                        weight={500}
                        right={16}
                        left={16}
                        styling="--menu"
                      >
                        Nedir?
                      </Rule>

                      <Rule
                        to="/post/nasil-kullanilir"
                        rule="Body"
                        weight={500}
                        right={16}
                        styling="--menu"
                      >
                        Nasıl Kullanılır?
                      </Rule>

                      <Rule
                        to="/post/etkinlik"
                        rule="Body"
                        weight={500}
                        right={16}
                        styling="--menu"
                      >
                        Etkinlik
                      </Rule>

                      <Rule
                        to="/blog"
                        rule="Body"
                        weight={500}
                        right={16}
                        styling="--menu"
                      >
                        Blog
                      </Rule>

                      <Rule
                        to="/post/s-s-s"
                        rule="Body"
                        weight={500}
                        right={16}
                        styling="--menu"
                      >
                        S.S.S
                      </Rule>


                      <Rule
                        to="/clinics"
                        rule="Body"
                        weight={500}
                        right={16}
                        styling="--menu"
                      >
                        Merkezler
                      </Rule>
                      <Rule
                        to="#references"
                        rule="Body"
                        weight={500}
                        styling="--menu"
                      >
                        Referanslarımız
                      </Rule>
                    </Box>
                    <Box display="flex" justify="space-between">
                      <Box
                        mode="padding"
                        top={12}
                        bottom={12}
                        left={24}
                        right={24}
                      >
                        <Rule
                          to="/rendezvous"
                          rule="Body"
                          weight={700}
                          color={Colors.primary}
                          styling="--menu"
                        >
                          Randevu Al
                        </Rule>
                      </Box>

                      <Box
                        mode="margin"
                        right={16}
                        border="1px solid #D6DDEB"
                      ></Box>

                      <Box
                        color={Colors.primary}
                        mode="padding"
                        top={12}
                        bottom={12}
                        left={24}
                        right={24}
                      >
                        <Rule
                          to="/collaboration"
                          rule="Body"
                          weight={700}
                          color={Colors.white}
                          styling="--menu"
                        >
                          İş Ortaklığı
                        </Rule>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : null
            }
          />
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
