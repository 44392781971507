const Colors = {
  primary: "#029ED0",
  secondary: "#EFFBFA",
  tertiary: "#EFFBFA",
  space: "#202430",
  black: "#000000",
  white: "#FFFFFF",
  rich: "#101115",
  gradient: (stops = [], direction = "right") => {
    let colors = "";
    stops.map((color) => (colors += "," + color));
    return "linear-gradient(to " + direction + colors + ")";
  },
  image: (url) => "url(" + url + ")",
};

export default Colors;
