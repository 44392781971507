import React, { useState, useEffect } from "react";
import { Box, Rule } from "..";
import { API } from "../../Modules";
import { Functions } from "../../Helpers";
import InputMask from "react-input-mask";
import DatePicker from "sassy-datepicker";
import Moment from "moment";
import Select from "react-select";
import Lodash from "lodash";

const Tabs = ({
  label,
  value = null,
  change,
  bottom = 24,
  placeholder = "Klinik Seçiniz",
  properties = {},
}) => {
  const [focused, setFocused] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [clinicValue, setClinicValue] = useState(null);
  const [placeHolder, setPlaceHolder] = useState(placeholder);

  const cityExistsInData = (city, data) => {
    let exists = undefined;

    data.map((d, dd) => {
      if (d.label === city) {
        exists = String(dd);
      }
    });

    return exists;
  };

  useEffect(async () => {
    const clinics = await API.request("/website/clinics");
    if (clinics.success) {
      setClinics(clinics.data);
    }
    if (value || clinicValue) {
      clinics.data.map((c) => {
        if (c.uuid === value || c.uuid === clinicValue) {
          setPlaceHolder(c.parameters.name + " - " + c.parameters.district);
          setClinicValue({
            label: c.parameters.name + " - " + c.parameters.district,
            value: c.uuid,
          });
        }
      });
    }
  }, []);

  let options = [];

  if (clinics.length > 0) {
    let sortedClinics = Lodash.clone(clinics);
    sortedClinics.sort((a, b) => {
      let sort = Functions.sort(a.parameters.city, b.parameters.city);
      if (sort === undefined) {
        sort = Functions.sort(a.parameters.district, b.parameters.district);
      }
      return sort;
    });

    sortedClinics.map((clinic) => {
      if (cityExistsInData(clinic.parameters.city, options)) {
        let existingOptionIndex = cityExistsInData(
          clinic.parameters.city,
          options
        );
        options[existingOptionIndex].options.push({
          label: clinic.parameters.name + " - " + clinic.parameters.district,
          value: clinic.uuid,
        });
      } else {
        options.push({ label: clinic.parameters.city, options: [] });
        let optionIndex = cityExistsInData(clinic.parameters.city, options);
        options[optionIndex].options.push({
          label: clinic.parameters.name + " - " + clinic.parameters.district,
          value: clinic.uuid,
        });
      }
    });
  }

  return (
    <Box bottom={bottom} width="100%">
      {label ? (
        <Rule
          display="block"
          rule="Body"
          weight="600"
          color="#515B6F"
          bottom={4}
        >
          {label}
        </Rule>
      ) : undefined}
      <Select
        value={clinicValue}
        placeholder={placeHolder}
        onChange={(data) => {
          if (change) {
            change(data.value);
            setClinicValue(data);
          }
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        style={{
          border: "1px solid #D6DDEB",
          height: 50,
          width: "100%",
          boxSizing: "border-box",
          paddingLeft: 12,
          paddingRight: 12,
          fontFamily: "LatoTR",
          fontWeight: "600",
          color: "#25324B",
          fontSize: 16,
        }}
        options={options}
        {...properties}
      />
    </Box>
  );
};

export default Tabs;
