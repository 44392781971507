import React from "react";
import { connect } from "react-redux";

import { Box, Rule } from "../Bitter";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const rules = [
      "Display",
      "Header",
      "Title",
      "Subtitle",
      "Large",
      "Body",
      "Small",
    ];

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        <Box all={24} mode="padding">
          {rules.map((rule) => (
            <Box bottom={24}>
              <Box bottom={4}>
                <Box
                  display="inline-block"
                  mode="padding"
                  all={6}
                  radius={8}
                  color="#F7F7F7"
                  shadow={true}
                >
                  <Rule rule="Small">{rule}</Rule>
                </Box>
              </Box>
              <Rule rule={rule} display="block">
                Almost before we knew it, we had left the ground.
              </Rule>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
