import React from "react";
import { Box, Button, Rule } from "..";
import { Colors } from "../../Helpers";

const Expert = ({
  name,
  image,
  profession,
  city,
  phone,
  email,
  width = "auto",
  mobile,
}) => {
  return (
    <Box
      display="flex"
      direction={mobile ? "column" : "row"}
      justify={!mobile && "space-between"}
      align={mobile ? "stretch" : "center"}
      width={width}
      border="1px solid #D6DDEB"
      mode="padding"
      all={24}
      style={{ marginBottom: 32 }}
    >
      <Box display="flex">
        <Box width={64} height={64} right={24}>
          <img
            src={image}
            style={{ width: "100%", height: "100%", borderRadius: 999 }}
          />
        </Box>
        <Box display="flex" direction="column">
          <Box bottom={8}>
            <Rule rule="Subtitle" family="LatoTR">
              {name}
            </Rule>
          </Box>
          <Box bottom={8}>
            <Rule rule="Body">
              {profession} • {city}
            </Rule>
          </Box>
          <Box display="flex" bottom={mobile ? 24 : 0}>
            {phone?.length && (
              <Box
                display="inline-flex"
                align="center"
                mode="padding"
                top={6}
                bottom={6}
                left={10}
                right={10}
                radius={80}
                border="1px solid #FFB836"
                style={{ marginRight: 8 }}
              >
                <Rule weight={600} family="LatoTR" size={14} line={22.4}>
                  <a href={`tel:${phone}`} style={{ color: Colors.primary }}>
                    Telefon
                  </a>
                </Rule>
              </Box>
            )}
            {email?.length && (
              <Box
                display="inline-flex"
                align="center"
                mode="padding"
                top={6}
                bottom={6}
                left={10}
                right={10}
                radius={80}
                border="1px solid #E9C46A"
                style={{ marginRight: 8 }}
              >
                <Rule weight={600} family="LatoTR" size={14} line={22.4}>
                  <a href={`mailto:${email}`} style={{ color: "#E9C46A" }}>
                    E-Posta
                  </a>
                </Rule>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="inline-flex" direction={mobile ? "column" : "row"}>
        {phone?.length && (
          <Button
            type="primary"
            style={{
              marginBottom: mobile ? 24 : 0,
              marginRight: mobile ? 0 : 10,
            }}
          >
            <a href={`tel:${phone}`} style={{ color: Colors.white }}>
              Telefonla Ara
            </a>
          </Button>
        )}
        {email?.length && (
          <Button type="primary">
            <a href={`mailto:${email}`} style={{ color: Colors.white }}>
              Mail gönder
            </a>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Expert;
