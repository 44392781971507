import Axios from "axios";
import Store from "./Store";
import Toast from "react-hot-toast";
import { Resources } from "../Helpers";

class API {
  static async request(slug, request = {}, announce = true) {
    const admin = Store.getState().admin;

    const promise = new Promise((resolve) => {
      Axios.post(Resources.url + slug, request, {
        headers: {},
      })
        .then((res) => {
          const data = res.data;

          if (data.error && announce) {
            Toast((t) => data.message);
          }

          console.log({
            url: Resources.url + slug,
            request,
            response: data.data,
          });

          resolve({
            success: data.success,
            error: data.error,
            message: data.message,
            data: data.data,
          });
        })
        .catch(() => {
          if (announce) {
            Toast((t) => "Bir sorun oluştu. Lütfen daha sonra deneyiniz.");
          }

          console.log({
            url: Resources.url + slug,
            request,
            headers: {},
            response: {
              success: false,
              error: true,
              message: "Bir sorun oluştu. Lütfen daha sonra deneyiniz.",
              data: null,
            },
          });

          resolve({
            success: false,
            error: true,
            message: "Bir sorun oluştu. Lütfen daha sonra deneyiniz.",
            data: null,
          });
        });
    });
    const result = await promise;
    return result;
  }

  static async silent(slug, request = {}) {
    return await this.request(slug, request, false);
  }

  static source(uuid) {
    return Resources.url + "/picture/" + uuid + ".jpg";
  }
}

export default API;
