import React, { useState } from "react";
import { Box, Icon, Rule } from "..";
import { Colors } from "../../Helpers";

const Checkbox = ({ title, press, value = false, bottom = 0 }) => {
  return (
    <Box display="flex" top={20} bottom={bottom}>
      <Box
        display="flex"
        align="center"
        justify="center"
        width={24}
        height={24}
        border={`1.5px solid ${value ? Colors.primary : "#D6DDEB"}`}
        radius={4}
        color={value ? Colors.primary : Colors.white}
        press={() => {
          let newValue = !value;
          if (press) {
            press(newValue);
          }
        }}
        right={16}
      >
        {value && <Icon icon="check" size={18} color={Colors.white} />}
      </Box>
      <Rule rule="Body">
        {title}
      </Rule>
    </Box>
  );
};

export default Checkbox;
