import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";

import {
  Box,
  Rule,
  Container,
  Mobile,
  Button,
  Static,
  StaticImage,
} from "../Bitter";
import { Colors, Functions } from "../Helpers";
import Service from "../Bitter/Organisms/Service";
import { useWebPSupportCheck } from "react-use-webp-support-check";

const WEBP = ({ render = false }) => {
  const supportsWebP = useWebPSupportCheck();
  if (render) {
    return render(supportsWebP);
  }
  return typeof render;
};

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Mobile
        render={(mobile) => (
          <Box
            identifier="what"
            color={Colors.white}
            mode="padding"
            top={mobile ? 0 : 72}
            bottom={mobile ? 24 : 72}
          >
            <Box display="flex" direction="column">
              <Mobile
                render={(mobile, minimum, gutter, spacing, screen) => (
                  <Box display="flex" position="relative">
                    <Container
                      grid={true}
                      render={(mobile) => (
                        <Col xs={12} sm={12} md={12} lg={8} xl={6}>
                          <Box
                            display="flex"
                            direction="column"
                            mode="padding"
                            top={mobile ? 0 : 72}
                            bottom={66}
                            width="100%"
                          >
                            {mobile ? (
                              <Box left={-15} right={-15}>
                                <StaticImage
                                  slug="home-services-1"
                                  render={(image) => (
                                    <img
                                      style={{
                                        display: "block",
                                        height: "auto",
                                        marginBottom: 24,
                                        width: "100%",
                                      }}
                                      src={image}
                                    />
                                  )}
                                />
                              </Box>
                            ) : undefined}
                            <Static slug="home-services-1" />

                            <Button
                              type="primary"
                              style={{ maxWidth: "191px" }}
                              press={() =>
                                (window.location =
                                  "/post/ems-teknolojisinin-essiz-faydalari")
                              }
                            >
                              Daha Fazla Bilgi Al
                            </Button>
                          </Box>
                        </Col>
                      )}
                    />
                    {!mobile ? (
                      <StaticImage
                        slug="home-services-1"
                        render={(image) => (
                          <img
                            style={{
                              position: "absolute",
                              right: 0,
                              top: "50%",
                              transform: "translateY(-50%)",
                              borderTopLeftRadius: 80,
                              borderBottomLeftRadius: 80,
                              width:
                                screen === "xxl"
                                  ? "auto"
                                  : Functions.getWidthFromColumns(
                                      screen,
                                      screen === "xl"
                                        ? 2
                                        : screen === "lg"
                                        ? 3
                                        : screen === "xxl"
                                        ? 2.5
                                        : 9999
                                    ),
                              display: "block",
                              height: screen === "xxl" ? "100%" : "auto",
                              maxHeight: "100%",
                              maxWidth: "45vw",
                            }}
                            src={image}
                          />
                        )}
                      />
                    ) : undefined}
                  </Box>
                )}
              />

              <Mobile
                render={(mobile, minimum, gutter, spacing, screen) => (
                  <Box display="flex" align="center" position="relative">
                    {!mobile ? (
                      <StaticImage
                        slug="home-services-2"
                        render={(image) => (
                          <img
                            style={{
                              position: "absolute",
                              left: 0,
                              borderTopRightRadius: 80,
                              borderBottomRightRadius: 80,
                              width:
                                screen === "xxl"
                                  ? "auto"
                                  : Functions.getWidthFromColumns(
                                      screen,
                                      screen === "xl"
                                        ? 2
                                        : screen === "lg"
                                        ? 3
                                        : screen === "xxl"
                                        ? 2.5
                                        : 9999
                                    ),
                              display: "block",
                              height: screen === "xxl" ? "100%" : "auto",
                              maxHeight: "100%",
                              maxHeight: "100%",
                              maxWidth: "45vw",
                            }}
                            src={image}
                          />
                        )}
                      />
                    ) : undefined}

                    <Container
                      grid={true}
                      render={(mobile) => (
                        <Col
                          style={{ marginLeft: "auto" }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={6}
                        >
                          <Box
                            display="flex"
                            direction="column"
                            mode="padding"
                            top={mobile ? 0 : 72 * 2}
                            bottom={66}
                          >
                            {mobile ? (
                              <Box left={-15} right={-15}>
                                <StaticImage
                                  slug="home-services-2"
                                  render={(image) => (
                                    <img
                                      style={{
                                        display: "block",
                                        height: "auto",
                                        marginBottom: 24,
                                        width: "100%",
                                      }}
                                      src={image}
                                    />
                                  )}
                                />
                              </Box>
                            ) : undefined}

                            <Static slug="home-services-2" />

                            <Button
                              type="primary"
                              style={{ maxWidth: "191px" }}
                              press={() =>
                                (window.location = "/post/nasil-kullanilir")
                              }
                            >
                              Daha Fazla Bilgi Al
                            </Button>
                          </Box>
                        </Col>
                      )}
                    />
                  </Box>
                )}
              />

              <Mobile
                render={(mobile, minimum, gutter, spacing, screen) => (
                  <Box display="flex" position="relative">
                    <Container
                      grid={true}
                      render={(mobile) => (
                        <Col
                          style={{ marginTop: screen === "xxl" ? 240 : 0 }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={6}
                        >
                          <Box display="flex" direction="column">
                            {mobile ? (
                              <Box left={-15} right={-15}>
                                <WEBP
                                  render={(support) =>
                                    support ? (
                                      <img
                                        style={{
                                          display: "block",
                                          height: "auto",
                                          marginBottom: 24,
                                          width: "100%",
                                        }}
                                        src="/assets/i/animation.webp"
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          display: "block",
                                          height: "auto",
                                          marginBottom: 24,
                                          width: "100%",
                                        }}
                                        src="/assets/i/animation.gif"
                                      />
                                    )
                                  }
                                />
                              </Box>
                            ) : undefined}

                            <Static slug="home-services-3" />

                            <Button
                              type="primary"
                              style={{ maxWidth: "191px" }}
                              press={() => (window.location = "/post/etkiler")}
                            >
                              Daha Fazla Bilgi Al
                            </Button>
                          </Box>
                        </Col>
                      )}
                    />
                    {!mobile ? (
                      <WEBP
                        render={(support) =>
                          support ? (
                            <img
                              style={{
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                borderTopLeftRadius: 80,
                                borderBottomLeftRadius: 80,

                                width:
                                  screen === "xxl"
                                    ? "auto"
                                    : Functions.getWidthFromColumns(
                                        screen,
                                        screen === "xl"
                                          ? 2
                                          : screen === "lg"
                                          ? 3
                                          : screen === "xxl"
                                          ? 2.5
                                          : 9999
                                      ),
                                display: "block",
                                height: screen === "xxl" ? "100%" : "auto",
                                maxHeight: "100%",
                                maxWidth: "45vw",
                              }}
                              src="/assets/i/animation.webp"
                            />
                          ) : (
                            <img
                              style={{
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                borderTopLeftRadius: 80,
                                borderBottomLeftRadius: 80,

                                width:
                                  screen === "xxl"
                                    ? "auto"
                                    : Functions.getWidthFromColumns(
                                        screen,
                                        screen === "xl"
                                          ? 2
                                          : screen === "lg"
                                          ? 3
                                          : screen === "xxl"
                                          ? 2.5
                                          : 9999
                                      ),
                                display: "block",
                                height: screen === "xxl" ? "100%" : "auto",
                                maxHeight: "100%",
                                maxWidth: "45vw",
                              }}
                              src="/assets/i/animation.gif"
                            />
                          )
                        }
                      />
                    ) : undefined}
                  </Box>
                )}
              />
            </Box>
          </Box>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
