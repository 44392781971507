import React from "react";
import FeatherIcon from "feather-icons-react";
import { Box } from "../../Bitter";
import { Colors } from "../../Helpers";
import Lodash from "lodash";

const Component = (props) => {
  const { icon, size = 24, press, color, weight, style } = props;

  let properties = props;
  if (properties.color) {
    properties = Lodash.omit(properties, "color");
  }

  return (
    <Box height={size} width={size} press={press} {...properties}>
      <FeatherIcon
        strokeWidth="2"
        size={size}
        icon={icon}
        weight={weight}
        stroke={color ? color : Colors.black}
        style={{ ...style, strokeWidth: weight }}
      />
    </Box>
  );
};

export default Component;
