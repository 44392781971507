import React from "react";
import { Box, Button, Icon, Rule, Mobile } from "..";
import { Colors } from "../../Helpers";

const Clinic = ({
  name,
  city,
  district,
  phone,
  email,
  address,
  width = "auto",
  mobile,
  rendezvous,
}) => {
  const chars = address.split(" ");
  let redirect = "https://www.google.com/maps/search/";
  chars.forEach((char, index) => {
    if (index !== chars.length - 1) {
      redirect += char + "+";
    } else {
      redirect += char;
    }
  });
  return (
    <Mobile
      render={(mobile) => (
        <Box
          display="flex"
          direction={mobile ? "column" : "row"}
          justify={!mobile && "space-between"}
          align={mobile ? "stretch" : "center"}
          width={width}
          border="1px solid #D6DDEB"
          mode="padding"
          all={24}
          style={{ marginBottom: 32 }}
        >
          <Box flex={1} display="flex" direction="column">
            <Box bottom={8}>
              <Rule rule="Subtitle" family="LatoTR">
                {name}
              </Rule>
            </Box>
            <Box bottom={8} display="flex" direction="column">
              <Rule rule="Body" color="#7C8493">
                {district} • {city}
              </Rule>

              <Rule rule="Body">
                <a
                  style={{ color: "black", display: "flex" }}
                  href={redirect}
                  target="_blank"
                >
                  {address} <Icon left={10} size={20} icon="map-pin"></Icon>
                </a>
              </Rule>

              <Rule rule="Body">{phone}</Rule>
            </Box>
            <Box display="flex">
              {phone?.length ? (
                mobile ? (
                  <Rule
                    weight={600}
                    family="LatoTR"
                    size={14}
                    line={22.4}
                    style={{ marginRight: 8 }}
                  >
                    <a href={`tel:${phone}`} style={{ color: Colors.primary }}>
                      Telefon
                    </a>
                  </Rule>
                ) : (
                  <Box
                    display="inline-flex"
                    align="center"
                    mode="padding"
                    top={6}
                    bottom={6}
                    left={10}
                    right={10}
                    radius={80}
                    border="1px solid #029ED0"
                    style={{ marginRight: 8 }}
                  >
                    <Rule weight={600} family="LatoTR" size={14} line={22.4}>
                      <a
                        href={`tel:${phone}`}
                        style={{ color: Colors.primary }}
                      >
                        Telefon
                      </a>
                    </Rule>
                  </Box>
                )
              ) : null}
              {email?.length ? (
                mobile ? (
                  <Rule
                    weight={600}
                    family="LatoTR"
                    size={14}
                    line={22.4}
                    style={{ marginRight: 8 }}
                  >
                    <a href={`mailto:${email}`} style={{ color: "#029ED0" }}>
                      E-Posta
                    </a>
                  </Rule>
                ) : (
                  <Box
                    display="inline-flex"
                    align="center"
                    mode="padding"
                    top={6}
                    bottom={6}
                    left={10}
                    right={10}
                    radius={80}
                    border="1px solid #029ED0"
                    style={{ marginRight: 8 }}
                  >
                    <Rule weight={600} family="LatoTR" size={14} line={22.4}>
                      <a href={`mailto:${email}`} style={{ color: "#029ED0" }}>
                        E-Posta
                      </a>
                    </Rule>
                  </Box>
                )
              ) : null}
            </Box>
          </Box>

          <Button
            press={rendezvous}
            style={{
              marginTop: 24,
              whiteSpace: "nowrap",
              marginLeft: mobile ? 0 : 24,
            }}
            type="primary"
          >
            Randevu Al
          </Button>
        </Box>
      )}
    />
  );
};

export default Clinic;
