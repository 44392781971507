import React from "react";
import { Mobile } from "../../Bitter";
import { Container, Row, Col } from "react-grid-system";

const Component = ({
  render,
  grid = false,
  container = {},
  row = {},
  style,
}) => {
  return (
    <Mobile
      render={(mobile) => (
        <Container {...container} style={{ width: "100%", ...style }}>
          {grid ? (
            <Row {...row}>{render ? render(mobile) : undefined}</Row>
          ) : render ? (
            render(mobile)
          ) : undefined}
        </Container>
      )}
    />
  );
};

export default Component;
