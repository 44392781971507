import React, { useState } from "react";
import { Box, Rule } from "..";
import InputMask from "react-input-mask";
import DatePicker from "sassy-datepicker";
import Moment from "moment";

const Tabs = ({
  label,
  value = "",
  change,
  bottom = 24,
  placeholder,
  mode = "input",
  options = [],
  type = "text",
  mask = false,
  date = false,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <Box bottom={bottom} width="100%">
      {label ? (
        <Rule
          display="block"
          rule="Body"
          weight="600"
          color="#515B6F"
          bottom={4}
        >
          {label}
        </Rule>
      ) : undefined}
      {mode === "select" ? (
        <select
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            if (change) {
              change(event.target.value);
            }
          }}
          style={{
            border: "1px solid #D6DDEB",
            height: 50,
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 12,
            paddingRight: 12,
            fontFamily: "LatoTR",
            fontWeight: "600",
            color: "#25324B",
            fontSize: 16,
          }}
        >
          <option value="" disabled selected={value === ""}>
            Seçiniz
          </option>

          {options.map((option) =>
            typeof option === "object" ? (
              <option
                selected={value === option.value}
                value={option.value}
                key={option.value}
              >
                {option.label}
              </option>
            ) : (
              <option selected={value === option} value={option} key={option}>
                {option}
              </option>
            )
          )}
        </select>
      ) : mask ? (
        <InputMask
          mask={mask}
          value={value}
          onChange={(event) => {
            if (change) {
              change(event.target.value);
            }
          }}
        >
          {(props) => (
            <div style={{ position: "relative" }}>
              <input
                type={type}
                placeholder={placeholder}
                value={props.value}
                onChange={props.onChange}
                style={{
                  border: "1px solid #D6DDEB",
                  height: 50,
                  width: "100%",
                  boxSizing: "border-box",
                  paddingLeft: 12,
                  paddingRight: 12,
                  fontFamily: "LatoTR",
                  fontWeight: "600",
                  color: "#25324B",
                  fontSize: 16,
                  display: "block",
                  lineHeight: "50px",
                }}
                onFocus={() => setFocused(true)}
              />
              {date && focused ? (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: "calc(100% + 12px)",
                    zIndex: 2
                  }}
                >
                  <DatePicker
                    onChange={(_date) => {
                      let formatted = Moment(_date).format("DD/MM/YYYY");
                      if(change){
                        change(formatted);
                      }
                      if (props.onChange) {
                        props.onChange(formatted);
                      }
                      setFocused(false);
                    }}
                    minDate={new Date()}
                  />
                </div>
              ) : undefined}
            </div>
          )}
        </InputMask>
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            if (change) {
              change(event.target.value);
            }
          }}
          style={{
            border: "1px solid #D6DDEB",
            height: 50,
            width: "100%",
            boxSizing: "border-box",
            paddingLeft: 12,
            paddingRight: 12,
            fontFamily: "LatoTR",
            fontWeight: "600",
            color: "#25324B",
            fontSize: 16,
            display: "block",
            lineHeight: "50px",
          }}
        />
      )}
    </Box>
  );
};

export default Tabs;
