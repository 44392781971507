import React from "react";
import { Box, Rule } from "..";
import { Colors, Resources } from "../../Helpers";
import Moment from "moment";
import "moment/locale/tr";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Blog = ({
  title,
  date,
  description,
  image,
  slug,
  width = "auto",
  attachment,
  debug,
}) => {
  Moment.locale("tr");
  const history = useHistory();
  return (
    <Box
      width={width}
      border="1px solid #D6DDEB"
      color={Colors.white}
      display="flex"
      direction="column"
      radius={4}
      press={() => {
        history.push(`/post/${slug}`);
      }}
      bottom={24}
    >
      <Box display="flex" justify="space-between">
        <Box
          width="100%"
          style={{ paddingTop: "80%", position: "relative", maxHeight: 250 }}
        >
          <img
            style={{
              height: "100%",
              width: "100%",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              position: "absolute",
              top: 0,
              left: 0,
            }}
            src={
              attachment
                ? typeof attachment === "string"
                  ? attachment.length > 0
                    ? Resources.url + "/uploads/preview/" + attachment + ".jpg"
                    : "https://theraphy.gumenita.com/api/uploads/preview/2b1ab225-3b01-4798-b37f-072b184df10c.jpg"
                  : "https://theraphy.gumenita.com/api/uploads/preview/2b1ab225-3b01-4798-b37f-072b184df10c.jpg"
                : "https://theraphy.gumenita.com/api/uploads/preview/2b1ab225-3b01-4798-b37f-072b184df10c.jpg"
            }
          />
        </Box>
      </Box>
      <Box left={24} right={24} top={12}>
        <Rule rule="Large" size={16} weight="600" style={{ height: 56 }} styling="--blog">
          {title}
        </Rule>
      </Box>
      {/* {date ? (
        typeof date === "string" ? (
          date.length > 0 ? (
            <Box bottom={16}>
              <Rule rule="Body">
                {Moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}
              </Rule>
            </Box>
          ) : undefined
        ) : undefined
      ) : undefined} */}
      {description ? (
        typeof description === "string" ? (
          description.length > 0 ? (
            <Box left={24} right={24} display="flex">
              <Rule
                family="LatoTR"
                weight={400}
                size={14}
                line={25.6}
                color="#7C8493"
                style={{
                  flex: 1,
                  overflow: "hidden",
                  height: 25.6,
                  whiteSpace: "nowrap",
                  paddingRight: 4,
                }}
              >
                {description?.substring(0, 72) + "..."}
              </Rule>
              <Rule
                family="LatoTR"
                weight={400}
                size={16}
                line={25.6}
                color="#7C8493"
              >
                ...
              </Rule>
            </Box>
          ) : undefined
        ) : undefined
      ) : undefined}
      <Box left={24} right={24} top={0} bottom={24}>
        <Rule rule="Small">
          <Link
            to={`/post/${slug}`}
            style={{ textDecoration: "none", color: Colors.primary }}
          >
            Devamını Oku
          </Link>
        </Rule>
      </Box>
      {/* <Box>
        <Box
          mode="padding"
          border={`1px solid ${Colors.primary}`}
          left={12}
          right={12}
          display="inline-flex"
          align="center"
          justify="center"
          style={{ marginTop: 12 }}
          height={32}
        >
          <Rule rule="Small">
            <Link
              to={`/post/${slug}`}
              style={{ textDecoration: "none", color: Colors.primary }}
            >
              Tümünü Oku
            </Link>
          </Rule>
        </Box>
      </Box> */}
    </Box>
  );
};

export default Blog;
