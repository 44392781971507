class Functions {
  static async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static setPageNumbers(pageNumbers, currentPage, totalPages) {
    let addToBeginning = 0;
    let index = 1;
    if (currentPage <= 3) {
      for (; index < totalPages; index++) {
        pageNumbers.push(index);
      }
      if (totalPages <= 5) {
        for (; index <= totalPages; index++) {
          pageNumbers.push(index);
        }
      }
    } else if (currentPage >= totalPages - 1) {
      addToBeginning += Math.abs(currentPage - (totalPages - 2));
      addToBeginning === 2
        ? pageNumbers.push(
            currentPage - 4,
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage
          )
        : pageNumbers.push(
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1
          );
    } else {
      pageNumbers.push(
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2
      );
    }
  }

  static getWidthFromColumns(screen, column = 12) {
    const gutters = {
      xs: window.innerWidth,
      sm: 540,
      md: 740,
      lg: 960,
      xl: 1140,
      xxl: 1540,
    };

    if (gutters[screen]) {
      return gutters[screen] / column;
    }
    return 0;
  }

  static sort(a, b) {
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }
}

export default Functions;
